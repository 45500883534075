//common css libary for wijmo and box display css changes
.exprx--common-block {
    border-radius: 10px !important;
    .eprx--block__header{
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;

    }
    .eprx--block__header_height{
            height: 45px !important;
    }
    .eprx--block__content:nth-child(2), .presc-Sml {
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
    .eprx--block__content:nth-child(3){
        margin-top: 0.8rem;
        border-radius: 10px;
    }
    .eprx--block__content:nth-child(4){
        margin-top: 0.8rem;
        border-radius: 10px;
    }
}

// No data Found
.eprx--block__footer {
      font-size: 21px;
      color:#71767D;
      text-align: center;
      margin-top: 2rem;
        i {
            font-size: 36px;
            background-color: white;
            font-weight: 100;
            position: relative;
            left: -46px;
            top: -12px;
        }
        span {
            width: 66.29px;
            height: 14.31px;
            background-color: #DFE0E1;
            border-radius: 50%;
            display: inline-block;
            margin-left: 20px;
        }
}
.enable--hotkey__buttons , .disable--hotkey__buttons, .Highlight--hotkey__buttons {
    border-radius: 5px !important;
}
.btn--red, .secondary, .inactive, .submit, .med-guide-inactive{
    border-radius: 5px !important;
}
button, .borderRadius_forBoxes, textarea, input{
    border-radius: 10px  !important;
}
.border_RLRadius
{
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
.border_AllRadius
{
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}
// For Buttons -> 5px
// Boxes and pop ups -> 10px
//Input and text area - > 10px
#iSearchBox {
    border-radius: 5px  !important;
}
.accordion-section-over {
    .card {
        border-radius: 10px  !important;
    }
}
// For highlighting not visible icons in the rows(grids) when that row is highlighted
.wj-state-focused .wj-state-multi-selected {
    .actions--Highlightfocus--icon {
        background-color: #80ADBF;
        color: #fff !important;
    }
    .Highlightfocus--benefitCheck {
        border: 2px solid #ffffff;
        color: #fff !important;
        &:hover {
            border: 2px solid #ffffff;
            color: #fff !important;
        }
    }
}
//Brand Cloros
.ornage_brand {
    color:  rgb(247, 148,29) !important;
 }
//Account setting Messaging tab 
.commonBox_bodyHeight{
    .eprx--block__content:nth-child(2) {
        height: 75px !important;
    }
}
.commonheader_boxHeight{
    .eprx--block__header {
        height: 45px;
        font-size: 18px;
        .eprx--header__heading{
            font-weight: 400 !important;
        }
    }
}
.removeMarBtm_forInput {
  .form-group {
    margin-bottom: 0rem !important;
  }
  .custom--input {
    height: 3.5rem !important;
}
}
.removeInput_height { 
    .custom--input {
        height: 2.1rem !important;
    }
}
.removeInput_height_4 { 
    .custom--input {
        height: 4rem !important;
    }
}
.padding_22px {
    padding: 22px !important;
}
.marginTop_26{
    margin-top: 26px !important;
}

.dualList {
    div.record-picker{
        border: none !important;

        background-color: #ECF0F5 !important;
    }
    .record-picker{
        border-radius: 0px 0px 5px 5px !important;
    }
    .record-picker:first-child {
        border-top-left-radius: 0px; 
        border-top-right-radius: 0px; 
        border-top: none;
    }
    .record-picker li {
        border-top:0px !important;
            border-bottom: 0px !important;
            border-top: thin solid #ddd;
            display: block;
            font-size: 0.85em;
            cursor: pointer;
            white-space: nowrap;
            border: 0.07rem solid #e5e5e5;
            background-color: #FFFFFF;
            box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
            border-radius: 5px;
            margin-bottom: 4px !important;
    }
     .btn-block {
        margin-bottom: 0px !important;
    }
    button{
        border-radius: 5px 5px 0px 0px !important;
        height: 45px;
        font-size: 18px;
    }
    .button-bar 
    {
        margin: auto !important;
        text-align: center !important;
        margin-top: 2px !important;
        padding: 15px;
        button{ 
            border-radius: 5px !important;
            width: 110px !important;
            height: 35px !important;
            margin-right: 10px  !important;;
        }
    }
    ul {
        background-color: #ECF0F5  !important;
        padding: 9px !important;
        max-height: revert !important; 
        min-height: 25rem !important;
    }
    label {
        color: #000 !important;
    }
    .box{
        margin-bottom: 0px !important;
    }
}
//While processing Batch eRx's
.batch_eRx_process{
    position: relative;
    right: 7.3rem;
    top: -3px;
    float: left;
}
//Brand Cloros
.ornage_brand {
   color:  rgb(247, 148,29) !important;
}
.bright_red{
    color: #f6251a !important;
}
.success_green{
    color: #51A351 !important;
}
#treeviewColor.wj-treeview{
    .wj-node.wj-state-selected {
        color: inherit !important;
        background: rgba(0, 0, 0, 20%) !important;
        display: inline !important;
    }
}
.privDropDown.wj-listbox{
    .wj-listbox-item.wj-state-selected {
        background: #31afe5 !important;
        color: #fff !important;
    }
    .wj-listbox-item:hover{
       background-color: #429cd0 !important;
        color: #fff !important;
    }
    .wj-listbox-item:not(.wj-state-selected):not(.wj-state-disabled):not(.wj-separator):hover{
        color:#fff !important;
    }
}
//water mark for rx,rpv verifications
.water_mark {
    position: absolute;
    top: 0p;
    left: 22rem;
    top: 34rem;
    justify-content: center;
    text-align: center;
    display: flex;
    justify-content: center;
    transform: rotate(-48deg);
    color: #877e7e !important;
    opacity: 0.3;
    font-size: 50px;
}
//water mark for drug pick verifications
.water_mark_dpv{
    position: absolute;
    top: 0p;
    left: -3rem;
    top: 16rem;
    justify-content: center;
    font-size: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    transform: rotate(-48deg);
    color: #877e7e !important;
    opacity: 0.3;
    font-size: 64px;
}
.water_mark_discontinued {
    position: absolute;
    position: absolute;
    top: 24rem;
    justify-content: center;
    font-size: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    transform: rotate(-48deg);
    color: #877e7e !important;
    opacity: 0.3;
    font-size: 46px;
}
.deleteCross_icon{
    font-size: 1.3rem;
    color: red;
}
.nav-pills .nav-link.default_active {
    box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.2) !important;
    background: white !important;
    color: black !important;

}
.preview_scroll{
    height: 80vh;
    overflow-y: scroll;
}
.eRxResult{
    height: 55rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
.border_10 {
    border-radius: 10px !important;
}
.dash--border {
    border: dashed;
    border-radius: 10px;
    border-width: thin;
    margin-left: -12px !important;
}
.water_mark_storageItem{
    position: absolute;
    top: 0p;
    left: -3rem;
    top: 11rem;
    justify-content: center;
    font-size: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    transform: rotate(-48deg);
    color: #877e7e !important;
    opacity: 0.3;
    font-size: 45px;
}
.water_mark_dpvStatus{
    position: absolute;
    top: 0p;
    left: -30rem;
    top: 3rem;
    justify-content: center;
    font-size: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    transform: rotate(-48deg);
    color: #877e7e !important;
    opacity: 0.3;
    font-size: 46px;
}
.height_67 {
    height: 67% !important;
}



//Notes related css
.light_orange {
    background-color: #ffcf97 !important;
}
.lightColor_orange {
    background-color: #fff8f0 !important;
}
.height_35{
    height: 35px !important;
}
.height_325{
    height: 3.25rem !important;
}
.height_202 {
    height: 237px !important ;
    overflow-y: scroll;
    overflow-x: hidden;
}
.height_285 {
    height: 273px !important;
}
.lightGray_color {
    color: #4c4545 !important;
}
.btn-gray  {
    background-color: gray !important;
}
.border_transparent {
    border: 1px solid transparent;
}
.edit_Note {
    background-color: #FFCDB6 !important;
}
.edit_Note_border {
    border: 1px solid #FFCDB6 !important;
}
.border_card_top {
    border-radius: 10px 10px 0px 0px !important
}
.add_Note_border {
    border: 1px solid #AADFF5 !important;
}
.border_card_bottom_forAdd {
    border-radius: 0px 0px 10px 10px !important;
}
.font_size_Notes {
    font-size: 0.8rem !important;
}
.light_primary {
    background-color: #AADFF5 !important;
}
.light_border {
    border: 1px solid #fedfbb !important;
}
.width_2rem {
    width: 2rem !important; 
}
.patient_Add {
    height: 2.5rem !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}
.disabled_icons {
    margin: 0rem 0.25rem 0rem 0.25rem;
    border-radius: 50%;
    font-size: 1.25rem;
}
.noteTrackHistory .track {
    background: white;
    border-bottom: 1px solid #fedfbb;
    border-top: 1px solid #fedfbb;
    padding: 10px;
    height: 6rem;
    overflow-y: scroll;
    overflow-x: hidden;
    color: gray !important;
}
.scroll_electronic {
    max-height: 17rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
.notes_display_scroll {
    max-height: 38rem;
    overflow-x: hidden;
    overflow-y: scroll;
}
.notesList_scroll {
    overflow-y: scroll;
    max-height: 55vh;
}
.notes_pop{
    max-height: 18rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
.rxNotes_label_display {
    max-height: 7rem;
    overflow-y: scroll;
    overflow-x: hidden;
    list-style-type:disc;
}
.card_header {
    background-color: #D6EFFA !important ;
}
.ellipsis_longText{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 135px;
    padding-right: 5px;
}
.cursor_pointer{
    cursor: pointer !important;
}
//Angular editor for Notes
.angular-editor-toolbar {
    height: 31px !important;
    border-radius: 12px 12px 0px 0px !important;
    padding-bottom: 0px !important;
    padding-top: 1px !important;
    margin-bottom: -1px;
    .fa,.fas {
    font-family: 'Font Awesome 5 Pro' !important;
    font-weight: 900; }
        border-radius: 12px 12px 0px 0px  !important;
    .angular-editor-toolbar-set:nth-child(3) {
          display: none !important;
    }
    button {
        border:  none !important;
    }
    
}

.angular-editor {
    border-radius:  12px 12px 0px 0px !important;
}
.angular-editor-wrapper  {
    .angular-editor-textarea {
        min-height: 107px      !important;
        border-radius: 12px !important;
        margin-top: 0px !important;
        min-height: 0px;
        border-radius: 0px 0px 12px 12px !important;
    }
}
.transfer-in{
    .angular-editor-wrapper  {
        .angular-editor-textarea {
            min-height: 31px      !important;
            height: 44px !important;
        }
    }
}
//Notes display 
.Notes_display {
    .col-4 {
        max-width: 29.5% !important;
    }
}
.notes_Listdisplay{
    height: 221px !important;
    border: 1px solid #fedfbb !important;
    border-radius: 5px;
}

// .anime-shake{
//     animation: shakeanm 0.3s  infinite !important;
// }

// @keyframes shakeanm {
//     0%{left: 13px }
//       25%{top: 13px }
//       50%{bottom: 13px }
//       75%{right: 13px }
//       100%{left: 13px }
//   }
    .mt-20 {
        margin-top: 20px !important;

    }

.reportcaret {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: right !important;
    padding-left: 0.5rem;
    // padding-right: 0.5rem !important;
    &::before {
        font-family: 'Font Awesome 5 Pro' !important;
        content: "\f106";
        color: $jet;
        display: inline-block;
        // margin-right: 0.42rem;
    }
}

.reportcaret-down::before {
    transform: rotate(180deg);
}

.dragdrop_marker:before{
    font-family: 'Font Awesome 5 Pro' !important;
    content: "\f58e";
    font-weight: 900;
    padding-right: 8px !important;
    pointer-events: none;
    color: #9F9F9F;
    padding-left: 0.4rem !important;
}

.cross_icon:after {
    font-family: 'Font Awesome 5 Pro' !important;
    content: "\f057";
    padding-left: 8px !important;
    cursor: pointer !important;
    font-weight: 300 !important;
    padding-right: 0.4rem !important;
}
#targetArea1 li .three_dotsVert ::after{
    font-family: 'Font Awesome 5 Pro' !important;
    content: "\f142";
    padding-left: 8px !important;
    cursor: pointer !important;
    font-weight: 300 !important;
}
.width_12rem{
    width: 12rem !important;
}
.width_10rem{
    width: 10rem !important;
}
.width_20rem{
    width: 20rem !important;
}
.label-data-docs {
    color: #000;
    font-size: 17px;
}
.height_223{
    height: 23.5rem !important;
}
.height_253{
    height: 25.3rem !important;
}
