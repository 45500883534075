html {
    font-size: 14px;
}
body {
    font-family: $default-font-family !important;
    background: $bodybg !important;
    color: $dflt-txt-clr;
}
.cust--login > body {
    background: white !important;
}
//used to set scrool bar
%scroll-bar {
    &::-webkit-scrollbar {
        width: 0.55rem;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $jumbo;
        border-radius: 3rem;
        box-shadow: 0.1rem 0.3rem 0.6rem $iron;
    }
}

//to remove the scroll bar
%unset-scroll-bar {
    &::-webkit-scrollbar {
        width: 0rem;
    }
}

//color mixins
@function solid-lighten($color, $percentage) {
    @return lighten($color, (1-$percentage) * 100%);
}
@function solid-darken($color, $percentage) {
    @return darken($color, (1-$percentage) * 100%);
}
@mixin rgba($type, $color, $percentage, $shade: ligthen) {
    @if $shade == lighten {
        @if $type == color {
            color: solid-lighten($color, $percentage);
            color: rgba($color, $percentage);
        }
        @if $type == bg {
            background-color: solid-lighten($color, $percentage);
            background-color: rgba($color, $percentage);
        }
    } @else {
        @if $type == color {
            color: solid-darken($color, $percentage);
            color: rgba($color, $percentage);
        }
        @if $type == bg {
            background-color: solid-darken($color, $percentage);
            background-color: rgba($color, $percentage);
        }
    }
}

input{
    text-transform: uppercase !important;
}

::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
::placeholder { /* Recent browsers */
    text-transform: none;
}

// Over riding pre-defined code
.content-wrapper {
    top: 5.6rem !important;
    position: relative;
    background-color: $catskill-White;
    margin-left: 195px !important;
    @include media-breakpoint-only(xs) {
        top: 9.3rem !important;
        .content {
            padding-left: 0.35rem !important;
            padding-right: 0.35rem !important;
        }
    }
    @include media-breakpoint-only(sm) {
        top: 10.7rem !important;
    }
    .content {
        padding-bottom: 0.5rem !important;
    }
}

.form-control {
    height: 2.57rem !important;
    border: 0.07rem solid solid-darken($shaded-bg-clr, 0.97) !important;
    border-radius: 0.28rem !important;
    color: $jet;
    font-weight: 400;
    &:hover,
    &:focus,
    &:active {
        border: 0.07rem solid $aluminium !important;
    }
    &::-webkit-input-placeholder {
        font-weight: 400 !important;
        font-size: 0.9rem !important;
        color: $base;
    }
}

.padding-0 {
    padding: 0px;
}

/* Start Custom Radio button CSS */
.was-validated {
    .custom-control {
        padding-right: 1.5rem;
        min-height: 2rem;
        top: 0.3rem;
        .custom-control-label {
            padding-left: 0.3rem;
            &::before,
            &::after {
                width: 1.3rem;
                height: 1.3rem;
                top: 0.15rem;
                background-size: 61% 63%;
            }
        }
    }
    .custom-control-input:valid ~ .custom-control-label::before,
    .custom-control-input.is-valid ~ .custom-control-label::before {
        border: 1px solid $jumbo !important;
        background-color: unset !important;
    }
    .custom-control-input:valid:checked ~ .custom-control-label::before,
    .custom-control-input.is-valid:checked ~ .custom-control-label::before {
        background-color: $primary !important;
        border: $primary !important;
    }
    &.custom-control--refillDue {
        padding-left: 2.5rem;
    }
    .custom-control--refillDue-scheduletask {
        padding-left: 13.7rem;
    }
}
/* End Custom Radio button CSS */

.sidenav--stats{
    .sidenav--stats__ele, .sidenav--stats__ele2{
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        margin-bottom: 0.4rem;
        margin-top: 0.4rem;
        cursor: pointer;
        a{
            width: 100%;
            display: block;
            padding: 0.1rem 0.3rem 0.5rem 0.3rem;
            color: $white;
            font-weight: 500;
            text-align: center;
            .pharmacy-label {
                font-size: 1rem;
            }
            .pharmacy-label-sm {
                margin-bottom: 0.5rem;
            }
            .pharmacy-value{
                font-size: 1.5rem;
                line-height: 0.5;
                // display: inline-flex;
                .sidenav--stats__reveal{
                    font-size: 1rem;
                    position: absolute;
                    right: 0px;
                    width: auto;
                }
            }
            .pharmacy-value-size-sm {
                font-size: 1rem !important;
            }
        }
        &:nth-child(4n+1) {
            a{
                background: $teal;
                &:hover {
                    color: $teal;
                    background: transparent;
                }
            }
        }
        &:nth-child(4n+2) {
            a {
                background: $orange;
                &:hover {
                    color: $orange;
                    background: transparent;
                }
            }
        }
        &:nth-child(4n+3) {
            a{
                background: $cyan;
                &:hover {
                    color: $cyan;
                    background: transparent;
                }
            }
        }
        &:nth-child(4n+4) {
            a{
                background: $teal;
                &:hover {
                    color: $teal;
                    background: transparent;
                }
            }
        }
        &:nth-child(4n+5) {
            a{
                background: $red;
                &:hover {
                    color: $red;
                    background: transparent;
                }
            }
        }
        // &:nth-child(5) {
        //     background: solid-lighten($orange, 0.9);
        // }
    }
    .sidenav--stats__ele2{
        .carousel{
            width: unset !important;
            background: $pink !important;
            &:hover {
                color: $pink !important;
                background: transparent !important;
            }
            a{
                background: transparent;
            }
            .carousel-control-prev, .carousel-control-next{
                span {
                    height: 38px;
                }
                opacity: 0.9 !important;
                width: 30px !important;
            }
            .carousel-indicators{
                display: none;
            }
            min-height: 60px;
        }
    }
}
/* start Buttons */

button[class="submit"],
.submit {
    background: $primary;
    border: 0.1rem solid $primary;
    border-radius: 0.2rem;
    color: $white;
    font-size: 1rem;
    height: 2.875rem;
    margin-left: 0.3rem;
    min-width: 6.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $default-font-family;
    line-height: normal;
    &:hover {
        color: $white;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
    }
    &:active,
    &:focus {
        border: 0.125rem solid $white;
    }
    span{
        background-color: $white !important;
        padding: 5px 9px;
        color: $primary !important;
        border-radius: 3px;
        margin-right:5px;
    }
}

button[class="secondary"],
.secondary {
    background: transparent;
    border: 0.125rem solid $secondary;
    border-radius: 5px !important;
    color: $secondary;
    font-size: 1rem;
    height: 2.875rem;
    margin: 0.1rem 0.625rem;
    min-width: 6.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $default-font-family;
    line-height: normal;
    &:hover,
    &:active,
    &:focus {
        color: $white;
        background: $secondary;
        border: 0.125rem solid $white;
        outline: 0;
        span{
            background-color: $white !important;
            padding: 5px 9px;
            color: $secondary !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
    span{
        background-color: $secondary !important;
        padding: 5px 9px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
    }
}


%enable--btns {
    padding: 0.52rem;
    min-width: 4.6rem;
    text-transform: uppercase;
    display: block;
    margin: 0 0.25rem 0.35rem 0;
    cursor: pointer;
    line-height: 1.3;

    .hotkey--buttons {
        background: $actn-btn-color;
        color: $white;
        border-radius: 50%;
        margin-right: 0.1875rem;
        float: left;
        font-size: 0.75rem;
        padding: 0.25rem;
        width: 1.625rem !important;
        text-transform: uppercase;
        position: relative;
        top: -0.15rem;
    }
    .save--buttons {
        color: $actn-btn-color;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
}
%highlight--btns {
    padding: 0.52rem;
    min-width: 4.6rem;
    text-transform: uppercase;
    display: block;
    margin: 0 0.25rem 0.35rem 0;
    cursor: pointer;
    line-height: 1.3;

    .hotkey--buttons {
        background:$white ;
        color: $actn-btn-color;
        border-radius: 50%;
        margin-right: 0.1875rem;
        float: left;
        font-size: 0.75rem;
        padding: 0.25rem;
        width: 1.625rem !important;
        text-transform: uppercase;
        position: relative;
        top: -0.15rem;
    }
    .save--buttons {
        // color: $white;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
}

%alert--btns {
    padding: 0.52rem;
    min-width: 4.6rem;
    text-transform: uppercase;
    display: block;
    margin: 0 0.25rem 0.35rem 0;
    cursor: pointer;
    line-height: 1.3;

    .hotkey--buttons {
        background: #f6251a;
        color: $white;
        border-radius: 50%;
        margin-right: 0.1875rem;
        float: left;
        font-size: 0.75rem;
        padding: 0.25rem;
        width: 1.625rem !important;
        text-transform: uppercase;
        position: relative;
        top: -0.15rem;
    }
    .save--buttons {
        color: #f6251a;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
}

button[class="enable--hotkey__buttons"],
.enable--hotkey__buttons {
    @extend %enable--btns;
    color: $actn-btn-color;
    background: $white;
    border: 0.125rem solid $actn-btn-color;
    .hotkey--buttons {
        // padding: 0.2rem 0.5rem 0.2rem 0.5rem;
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    &:hover,
    &:active,
    &:focus {
        background: $actn-btn-color;
        border: 0.125rem solid $actn-btn-color;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $actn-btn-color;
            // padding: 0.2rem 0.5rem 0.2rem 0.5rem;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}
button[class="disable--hotkey__buttons"],
.disable--hotkey__buttons {
    @extend %enable--btns;
    color: $inactive-button-color;
    background: $white;
    border: 0.125rem solid $inactive-button-color;
    cursor: not-allowed;
    &:hover,
    &:active,
    &:focus {
        color: $inactive-button-color;
        background: $white;
        border: 0.125rem solid $inactive-button-color;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
    }
    .hotkey--buttons {
        background: transparent;
        color: $inactive-button-color;
        border: 0.1rem solid $inactive-button-color;
        padding: 0.25rem 0.4rem;
    }
    .save--buttons {
        color: $inactive-button-color;
    }
}

button[class="alert--hotkey__buttons"],
.alert--hotkey__buttons {
    @extend %alert--btns;
    color: #f6251a;
    border-radius: 0.28rem !important;
    background: $white;
    border: 0.125rem solid #f6251a;
    .hotkey--buttons {
        // padding: 0.2rem 0.5rem 0.2rem 0.5rem;
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    &:hover,
    &:active,
    &:focus {
        background: #f6251a;
        border: 0.125rem solid #f6251a;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $actn-btn-color;
            // padding: 0.2rem 0.5rem 0.2rem 0.5rem;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="Highlight--hotkey__buttons"],
.Highlight--hotkey__buttons {
    @extend %highlight--btns;
    color: $white;
    background: $actn-btn-color;
    border: 0.125rem solid $white;
    .hotkey--buttons {
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    &:hover,
    &:active,
    &:focus {
        // background: $white;
        background: $actn-btn-color;
        border: 0.125rem solid;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            color: $actn-btn-color;
            background:  $white;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="ternary"],
.ternary {
    background: $primary;
    border: 0.1rem solid $primary;
    border-radius: 0.2rem;
    color: $white;
    font-size: 1rem;
    height: 2.1rem;
    margin: 0.1rem 0.625rem;
    min-width: 6.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $default-font-family;
    line-height: normal;
    &:hover {
        color: $white;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
    }
    &:active,
    &:focus {
        border: 0.125rem solid $white;
    }
}



button[class="inactive"],
.inactive {
    @extend .submit;
    background-color: $white-smoke;
    padding: 5px;
    color: $inactive-button-color;
    border: 0.125rem solid $inactive-button-color;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    cursor: no-drop !important;
    margin: 0rem 0.3rem 0rem 0.3rem !important;
    min-width: 5.5rem !important;
    span{
        background-color: rgb(168, 168, 168) !important;
        padding: 5px;
        color: rgb(245, 245, 245) !important;
        border-radius: 16px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
        font-weight: bold;
    }
    &:hover,&:active,&:focus {
        color: $inactive-button-color;
        background: $white-smoke;
        border: 0.125rem solid $inactive-button-color;
        box-shadow: none;
        outline: 0px;
        span{
            background-color: rgb(168, 168, 168) !important;
        }
    }
    b{
        background-color: $primary !important;
        padding: 5px 9px;
        color: $white !important;
        border-radius: 3px;
        margin-right:5px;
    }
    &.inactive-rph {
        height: 3.5rem;
        width: 100px;
        font-weight: 700;
    }
    b{
        background-color:$inactive-button-color!important;
        padding: 5px 9px;
        color: $white !important;
        border-radius: 13px;
        margin-right:5px;
    }
    &.inactive-active--btnheight {
        min-width: 6.5rem;
        height: 2rem !important;
        margin-top: 0.3rem !important
    }
    &.inactive--btn-height {
        margin-top: 1.5rem !important;
    }
}
.btn--red {
    background-color: $red !important;
    border: 0.125rem solid $red !important;
}

.btn--green {
    background-color: $green !important;
    border: 0.125rem solid $green !important;
}

select.rx--select{
    min-height: 2.57rem !important;
    height: 2.57rem !important;
    color: $jet;
    cursor: default;
    display: flex;
    outline: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    border: 0.07rem solid solid-darken($shaded-bg-clr, 0.97) !important;
    min-height: 36px;
    align-items: center;
    border-radius: 0.28rem !important;
    text-transform: uppercase;
    &:disabled {
        cursor: not-allowed;
    }
    &:focus, &:active, &:hover {
        border: 0.07rem solid $light-blue !important;
        background-color: $input-focus !important ;
        color: black !important;
    }
    &.noTexttransform {
        text-transform: none !important;
    }
}
/* End Buttons */

/* Start select dropdown */
.ng-select {
    .ng-select-container {
        height: 2.85rem !important;
    }
}

.dropdown-scroll{
    .ng-select {
        .ng-select-container {
            height: 2.85rem !important;
            overflow-y: scroll !important;
            display:flex !important;
            flex-wrap:wrap !important;
        }
    }
}

.typehead-list {
    .dropdown-menu.show {
        height: 10.71rem;
        overflow: auto;
    }
}
/* End select dropdown */

/* start custom input css */
.return-to-stock-custom--input {
    .custom--input{
        height: 2rem !important;
        margin-bottom: 0px !important;
    }
}

.refill-due-report-height--top {
    .custom--date {
            position: relative;
        top: 20px;
    }
}
.dms_dpcuemnt_queue-custom--input {
    .custom--input{
        height: 1.9rem !important;
    }
}


.custom--input {
    height: 4.1rem;
    label {
        width: 100%;
        margin-bottom: 0px !important;
        font-weight: 400;
        font-family: $secondary-font-family;
        span {
            padding-left: 0.3rem;
        }
    }
    input {
        text-transform: uppercase;
        &:disabled {
            cursor: not-allowed;
        }
        &:focus, &:active, &:hover {
            border: 0.07rem solid $light-blue !important;
        }
    }
    .help--text__icons{
        position: relative;
        float: right;
        right: 22px;
    }
    .inactive{
        margin: 0px !important;
    }
    .clear--icon{
        padding-top: 2px;
        position: absolute;
        top: 0.6rem;
        right: -8px;
        width: 20px;
        height: 20px;
        background: #ccc;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
        z-index: 1;
        .fa-times{
            color: $danger;
        }
    }
}

.custom--disable--select {
    .ng-arrow-wrapper {
        display: none !important;
    }
}

.custom--input-radio{
    .help-block{
        position: absolute;
    }
}
.reset_height {
    .custom--input {
        height:unset !important;
    }
}
.remove--height__custom--input {
    .custom--input {
        height: unset;
    }
}

.custom--select {
    position: relative;
    top: -0.1rem;
    .custom--select__span {
        position: relative;
        top: unset;
        .custom--select__dataflow {
            text-overflow: ellipsis;
            overflow: hidden;
            text-transform: uppercase;
            min-width: 6rem;
            max-width: 60rem;
        }
    }
}

.custom--date {
    position: relative;
    top: -0.1rem;
    .custom--select__span {
        .form-control {
            position: relative;
            top: -0.1rem;
        }
    }
    .dropdown-menu{
        &.show{
            width:auto !important;
            overflow-y: unset !important;
        }
    }
}

input[class="inactive"],
.inactive {
    background: $white-smoke;
    border: 0.125rem solid $inactive-button-color;
    border-radius: 0.1875rem;
    font-size: 1rem;
    height: 2.875rem;
    margin: 0.1rem 0.625rem;
    min-width: 7rem;
    text-transform: uppercase;
    font-weight: 250;
    font-family: $default-font-family;
    cursor: no-drop;
    &:hover,
    &:active,
    &:focus {
        // border: 0.0625rem solid $inactive-button-color;
        // color: $inactive-button-color;
        outline: 0;
    }
    &:disabled {
        cursor: not-allowed;
    }

}

.control-label {
    line-height: 1.3;
    width: 100%;
    span {
        color: $cyan;
        margin-bottom: 0px !important;
        font-weight: 400;
        font-family: $default-font-family;
        padding: 0px !important;
        .help--text__danger{
            padding: unset !important;
        }
    }
}


.help-block {
    line-height: 1.1;
    font-size: 12px;
    position: relative;
    // top: -3px;
    float: right;
}

.form-group.has-error .form-control, .form-group.has-error .ng-select .ng-select-container,
.form-group.has-error .input-group-addon, .form-group.has-error .rx--select {
    border-color: $danger !important;
}
/* start custom input css */

label {
    font-weight: 600;
    margin-bottom: 0px;
}

.ng-clear-wrapper {
    display: none !important;
}

/* start Do not delete this - for removing blur border of input elem */
/* turn it off completely */
select {
    &:active,
    &:hover,
    &:focus {
        outline: none;
    }
    &:disabled {
        cursor: not-allowed;
    }
}
/* End - for removing blur border of input elem */

.ng-select.ng-select-single .ng-select-container {
    min-height: 2.57rem !important;
    height: 2.57rem !important;
}
.ng-select.inline-height .ng-select-container {
    min-height: 1.57rem !important;
    height: 2.2rem !important;
}
.ng-select.service-levlcheck .ng-select-container  {            
    border: 2px solid #0fa20f;
}

.ng-select.service-levluncheck .ng-select-container  {            
    border: 2px solid #f6251a;
}

.label--data {
    &.larger-label-data {
    font-size: 1rem;
    font-weight: bolder;
    }
    text-transform: uppercase;
    margin-bottom: 0.35rem;
    word-wrap: break-word;
}

.link {
    color: $primary !important;
    font-weight: 700;
    cursor: pointer;
}

/* Start Perfect scroll bar styling */
.ps,
.ps--scrolling-y,
.ps--active-y {
    .ps__rail-y {
        width: 0.5rem !important;
        background: transparent !important;
        &:hover,
        &:active,
        &:focus {
            width: 0.5rem !important;
            background: transparent !important;
            .ps__thumb-y {
                width: 0.5rem !important;
            }
        }
        &.ps--clicking {
            width: 0.5rem !important;
            background: transparent !important;
            .ps__thumb-y {
                width: 0.5rem !important;
            }
        }
    }
}
/* End Perfect scroll bar styling */

/* start ngb Typehead stling */
.typehead--result {
    padding: 0.5rem;
    min-width: 17rem;
    .typehead--content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

// check box styles
input[type="checkbox"] + label {
   // display: block;
    cursor: pointer;
    padding-left: 2rem;
    &:active {
        &::before {
            transform: scale(0);
        }
    }
}

input[type="checkbox"]:checked + label:before {
    background-color: $primary !important;
    border-color: $primary !important;
    color: $white;
}
.wj-col-picker-dropdown {
    .wj-col-picker-item, .wj-listbox-item  {
        input[type="checkbox"] {
            display: inline !important;
        }
    }
}

//date time picker
.ngb-tp-hour,
.ngb-tp-minute {
    .btn.btn-link {
        display: none;
    }
    .form-control {
        margin-top: 1.15rem;
    }
}

.padding-1rem {
    padding-left: 1rem;
    padding-right: 1rem;
}

.add--other-val__icon {
    position: relative;
    top: 1.5rem;
}

input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    display: none;
}

.custom--pagination{
    background: $secondary !important;
    margin-left: 0rem;
    margin-right: 0rem;
    span{
        i{
            font-size: 1.5rem;
        }
        text-align: center;
    }
    .icons{
        // background: $secondary !important;
        padding: 1rem;
        height: 4rem;
    }
}
.wrkStng__hotkey--sucess {
    height: 3.5rem !important;
    line-height: unset !important;
}

.hotkey_primary--Override {
    height: 2.4rem !important;
    min-width: 6rem !important;
}
.hotkey_primary--Override-Global{
    height: 3rem !important;
    margin-top: -8px !important;
}

.hotkey_primary--Override-rph--verify {
    height: 5.8rem !important;
    margin-top: -8px !important;
    width: 101px;
}

.count_shown-rph--verify {
    margin-top: -2px;
    color: orange;
    font-size: 1.5rem;
}

.hotkey_primary--add-button{
    height: 3.2rem !important;
    margin-top: -7px !important;
}
.hotkey_primary{
    @extend .submit;
    background-color: $white !important;
    padding: 5px 9px;
    color: $primary !important;
    // border-radius: 25px;
    border: 0.125rem solid $primary;
    border-radius: 5px;

    span{
        &::before {
            content:"\2303  ";
        }
        background-color: $primary !important;
        padding: 5px 9px;
        color: $white !important;
        border-radius: 3px;
        margin-right:5px;

        &.rph-btn{
            padding: 2px !important;
            margin-right: 2px;
        }

    }



    b{
        background-color: $primary !important;
        padding: 5px 9px;
        color: $white !important;
        border-radius: 3px;
        margin-right:5px;
    }
    .add_key {
        border-radius: 25px !important;
    }
    &:hover,&:focus {
        color: $white !important;
        background: $primary !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            &::before {
                content:"\2303  ";
            }
            background-color: $white !important;
            padding: 5px 9px;
            color: $primary !important;
            border-radius: 3px;
            margin-right:5px;
            .add_key {
                border-radius: 25px !important;
            }

            &.rph-btn{
                padding: 2px !important;
                margin-right: 2px;
            }

        }
        b{
            background-color: $white !important;
            padding: 5px 9px;
            color: $primary !important;
            border-radius: 3px;
            margin-right:5px;
        }
    }
}

.hotkey_primary

.alert_update{
    @extend .submit;
    background-color: $pink;
    color: $white;
    height: 2.1rem;
    border: none;
    &:hover, &:focus {
        color: $pink;
        background: $white;
        border: 1px solid $pink !important;
    }
}

.userGuideIconPosition {
    padding-left: 9px;
    padding-top: 8px;
    color: #f7941d;
    font-size: 22px;
    font-weight:600;
    cursor: pointer;
}
.usrGuidFrBlueBg{
    background-color: white;
    color: #f7941d;
    font-size: 20px;
    border-radius: 50%;
    font-weight:600;
    padding-left: 0px !important;
    padding-top: 0px !important;
    cursor: pointer;
}
.text-right {
    text-align: right;
}

.hotkey_success--Override{
    @extend .submit;
    background-color: $white !important;
    padding: 5px 9px;
    color: $primary !important;
    // border-radius: 25px;
    border: 0.125rem solid $primary;
    span{
        background-color: $primary !important;
        padding: 5px 9px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $primary !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px 9px;
            color: $primary !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}


.hotkey_success{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: $success !important;
    // border-radius: 25px;
    border: 0.125rem solid $success;
    border-radius: 5px;
    span{
        background-color: $success !important;
        padding: 5px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $success !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: $success !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}


.bucket_grid {
    padding-top: 1rem;
}


.bucket_grid--size {
    height: 2.5rem;
}

.close-button {
    padding-right: 10px;
    top: 13px;
    position: relative;
}
.close-button-1 {
    padding-right: 22px;
    top: 25px;
    position: relative;
}

.quick-close-button {
    &:hover,
    &:focus,
    &:active {
        border: none;
    }
}

.line-space {
    line-height: 1.6;
    text-align: center;
}




.tooltiptext {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 37%;
    transition: opacity 0.3s;
  }

.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
.algyName {
    font-size: 1.3rem;
    color: #f6251a;
}

.presc-Sml {
    .sml-box {
        .label--data {
            height: auto;
            max-height: 4.5rem;
            white-space: unset !important;
            overflow-y: auto !important;
        }
        &.semi-sml {
            .label--data {
                max-height: 3rem !important;
            }
        }
    }
}



.inactiv-colr{
    color: $white-smoke;
}

.btn-sm-width{
    min-width: unset !important;
    width: 2.5rem;
}
.btn-sm-width2{
    min-width: unset !important;
    width: 2.9rem;
    margin: 0.1rem 4.625rem !important;
    .far {
        padding:  1rem !important;
    }
}
.btn-sm-width1{
    min-width: unset !important;
    width: 2.9rem;
    margin: 0.1rem 1.625rem !important;
    .far {
        padding:  1rem !important;
    }
}
.btn-time-pick {
    position:relative;
    .btn-link {
        display: unset !important;
    }
}
.btn-time-picker {

    position:relative;
    .btn-link {
       display: grid !important;
       margin-left: 8px;
    }
}
.align-time {
    .ngb-tp-hour {
        .form-control {
            margin-top: unset !important;
        }
    }
    .ngb-tp-minute {
        .form-control {
            margin-top: unset !important;
        }
    }
}

.btn-meridian {
    .ngb-tp-meridian {
        .btn {
            border: 1px solid;
        }
    }
}

.task-top {
    top: 14px;
    position: relative;
}

.align--checkbox {
    top: 10px;
    left: 12px;
    position: relative;
}

.far {
    cursor: pointer;
    &.refillduefar {
        line-height: 0.5;
    }

}
.padding--left_refilldue {
    padding-left: 2.5rem;
}

.reduce-gap {
    .custom-control-label {
        padding-left: 0.2rem !important;
    }
}

.hotkey_success--black{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: #212121  !important;
    // border-radius: 25px;
    border: 0.125rem solid skyblue;
    font-weight: 500;
    text-transform: none !important;
    span{
        background-color: #3c8dbc !important;
        padding: 5px;
        color: #ffffff !important;
        border-radius: 16px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
        // border: solid #000000 !important;
        font-weight: bold;
    }
    &:hover,&:focus {
        color: $white !important;
        background: skyblue !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: #3c8dbc !important;
            border-radius: 16px;
            margin-right:5px;
        }
    }
}

.hotkey_disable--black{
    @extend .submit;
    background-color: $white-smoke;
    padding: 5px;
    color: $inactive-button-color;
    border: 0.125rem solid $inactive-button-color;
    font-weight: 500;
    text-transform: none !important;
    cursor: no-drop !important;
    span{
        background-color: rgb(168, 168, 168) !important;
        padding: 5px;
        color: rgb(245, 245, 245) !important;
        border-radius: 16px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
        font-weight: bold;
    }
    &:hover,&:active,&:focus {
        color: $inactive-button-color;
        background: $white-smoke;
        border: 0.125rem solid $inactive-button-color;
        box-shadow: none;
        outline: 0px;
        span{
            background-color: rgb(168, 168, 168) !important;
        }
    }
}

.configure-color {
        height: 3rem !important;
}
.params-buttons {
        @extend .submit ;
        text-transform:unset !important;
        color:#17a2b8 !important ;
        border: 0.125rem solid #17a2b8;
    // &:hover,&:focus {
    //     color: $white !important;
    //     background: $primary !important;
    // }

}
.no-textTransform {
    .custom--input {
        input {
            text-transform: none !important;
        }
    }
}
.min-width{
    min-width:6.2rem;
}

.phone-symbol__3 {
    padding: 1.5rem 0.1rem 1rem 0.1rem;
    left:3px;
    max-width:0.6rem;
}
.phone-symbol__9 {
    padding: 1.5rem 0.1rem 1rem 0.1rem;
    left:-9px;
    max-width:0.6rem;
}
.min-width__SendSMS {
    min-width: 3rem !important;
}

.acc-set-stateCityZip{
    .zip_line_height{
        .control-label {
            line-height: 1.9;
            width: 100%;
            span {
                color: $lightSkyBlue !important;
                margin-bottom: 0px !important;
                font-weight: 400;
                font-family: "Roboto" !important;
                padding: 0px !important;
                .help--text__danger{
                    padding: unset !important;
                        color: $danger !important;
                }
            }
        }
    }
}
.bg--Green--Clr--White{
    background-color: #51a351;
    color: white !important;
}
.bg--White--Clr--Green{
    background-color: white !important;
    color: #51a351 !important;
}
.rxStatus{
    border-radius: 4px;
    padding: 1px 2px 1px 3px;
}
%patial_highlight--btns {
    padding: 0.52rem;
    min-width: 4.6rem;
    text-transform: uppercase;
    display: block;
    margin: 0 0.25rem 0.35rem 0;
    cursor: pointer;
    line-height: 1.3;

    .hotkey--buttons {
        background:#f7941d ;
        color: $white;
        border-radius: 50%;
        margin-right: 0.1875rem;
        float: left;
        font-size: 0.75rem;
        padding: 0.25rem;
        width: 1.625rem !important;
        text-transform: uppercase;
        position: relative;
        top: -0.15rem;
    }
    .save--buttons {
        color: #f7941d;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
}
button[class="Partial--hotkey__buttons"],
.Partial--hotkey__buttons {
    @extend %patial_highlight--btns;
    color: #f7941d;
    border-radius: 0.28rem !important;
    background: $white;
    border: 0.125rem solid #f7941d;
    .hotkey--buttons {
        // padding: 0.2rem 0.5rem 0.2rem 0.5rem;
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    &:hover,
    &:active,
    &:focus {
        background: #f7941d;
        border: 0.125rem solid #f7941d;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $actn-btn-color;
            // padding: 0.2rem 0.5rem 0.2rem 0.5rem;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="pa-1-hotkey__buttons"],
.pa-1-hotkey__buttons {
    @extend %enable--btns;
    color: $pending;
    background: $white;
    border: 0.125rem solid $pending;
    border-radius: 5px;
    .hotkey--buttons {
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    .save--buttons {
        color: $pending;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    &:hover,
    &:active,
    &:focus {
        background: $pending;
        border: 0.125rem solid $pending;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $pending;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="pa-2-hotkey__buttons"],
.pa-2-hotkey__buttons {
    @extend %enable--btns;
    color: $appleGreen;
    background: $white;
    border: 0.125rem solid $appleGreen;
    border-radius: 5px;
    .hotkey--buttons {
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    .save--buttons {
        color: $appleGreen;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    &:hover,
    &:active,
    &:focus {
        background: $appleGreen;
        border: 0.125rem solid $appleGreen;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $appleGreen;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="pa-3-hotkey__buttons"],
.pa-3-hotkey__buttons {
    @extend %enable--btns;
    color: $brightOrange;
    background: $white;
    border: 0.125rem solid $brightOrange;
    border-radius: 5px;
    .hotkey--buttons {
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    .save--buttons {
        color: $brightOrange;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    &:hover,
    &:active,
    &:focus {
        background: $brightOrange;
        border: 0.125rem solid $brightOrange;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $brightOrange;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="pa-6-hotkey__buttons"],
.pa-6-hotkey__buttons {
    @extend %enable--btns;
    color: $tuatara;
    background: $white;
    border: 0.125rem solid $tuatara;
    border-radius: 5px;
    .hotkey--buttons {
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    .save--buttons {
        color: $tuatara;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    &:hover,
    &:active,
    &:focus {
        background: $tuatara;
        border: 0.125rem solid $tuatara;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $tuatara;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="pa-7-hotkey__buttons"],
.pa-7-hotkey__buttons {
    @extend %enable--btns;
    color: $burntRed;
    background: $white;
    border: 0.125rem solid $burntRed;
    border-radius: 5px;
    .hotkey--buttons {
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    .save--buttons {
        color: $burntRed;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    &:hover,
    &:active,
    &:focus {
        background: $burntRed;
        border: 0.125rem solid $burntRed;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $burntRed;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="pa-8-hotkey__buttons"],
.pa-8-hotkey__buttons {
    @extend %enable--btns;
    color: $brightRed;
    background: $white;
    border: 0.125rem solid $brightRed;
    border-radius: 5px;
    .hotkey--buttons {
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    .save--buttons {
        color: $brightRed;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    &:hover,
    &:active,
    &:focus {
        background: $brightRed;
        border: 0.125rem solid $brightRed;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $brightRed;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="pa-9-hotkey__buttons"],
.pa-9-hotkey__buttons {
    @extend %enable--btns;
    color: $lightBrown;
    background: $white;
    border: 0.125rem solid $lightBrown;
    border-radius: 5px;
    .hotkey--buttons {
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    .save--buttons {
        color: $lightBrown;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    &:hover,
    &:active,
    &:focus {
        background: $lightBrown;
        border: 0.125rem solid $lightBrown;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $lightBrown;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="pa-11-hotkey__buttons"],
.pa-11-hotkey__buttons {
    @extend %enable--btns;
    color: $orangePink;
    background: $white;
    border: 0.125rem solid $orangePink;
    border-radius: 5px;
    .hotkey--buttons {
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    .save--buttons {
        color: $orangePink;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    &:hover,
    &:active,
    &:focus {
        background: $orangePink;
        border: 0.125rem solid $orangePink;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $orangePink;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

button[class="pa-12-hotkey__buttons"],
.pa-12-hotkey__buttons {
    @extend %enable--btns;
    color: $deepPurple;
    background: $white;
    border: 0.125rem solid $deepPurple;
    border-radius: 5px;
    .hotkey--buttons {
        min-width: 1.8rem;
        &::before {
            content:"- ";
        }
    }
    .save--buttons {
        color: $deepPurple;
        font-weight: 700;
        position: relative;
        font-size: 0.75rem;
        text-transform: uppercase;
    }
    &:hover,
    &:active,
    &:focus {
        background: $deepPurple;
        border: 0.125rem solid $deepPurple;
        outline: 0;
        box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.3);
        .hotkey--buttons {
            background: $white;
            color: $deepPurple;
            min-width: 1.8rem;
        }
        .save--buttons {
            color: $white;
        }
        &::after{
            top: 2px;
            position: relative;
            color: white;
        }
    }
}

.pa-1-hotkey_success{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: $pending !important;
    // border-radius: 25px;
    border: 0.125rem solid $pending;
    border-radius: 5px;
    span{
        background-color: $pending !important;
        padding: 5px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $pending !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: $pending !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}

.pa-2-hotkey_success{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: $appleGreen !important;
    // border-radius: 25px;
    border: 0.125rem solid $appleGreen;
    border-radius: 5px;
    span{
        background-color: $appleGreen !important;
        padding: 5px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $appleGreen !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: $appleGreen !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}

.pa-3-hotkey_success{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: $brightOrange !important;
    // border-radius: 25px;
    border: 0.125rem solid $brightOrange;
    border-radius: 5px;
    span{
        background-color: $brightOrange !important;
        padding: 5px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $brightOrange !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: $brightOrange !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}

.pa-6-hotkey_success{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: $tuatara !important;
    // border-radius: 25px;
    border: 0.125rem solid $tuatara;
    border-radius: 5px;
    span{
        background-color: $tuatara !important;
        padding: 5px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $tuatara !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: $tuatara !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}

.pa-7-hotkey_success{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: $burntRed !important;
    // border-radius: 25px;
    border: 0.125rem solid $burntRed;
    border-radius: 5px;
    span{
        background-color: $burntRed !important;
        padding: 5px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $burntRed !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: $burntRed !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}

.pa-8-hotkey_success{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: $brightRed !important;
    // border-radius: 25px;
    border: 0.125rem solid $brightRed;
    border-radius: 5px;
    span{
        background-color: $brightRed !important;
        padding: 5px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $brightRed !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: $brightRed !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}

.pa-9-hotkey_success{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: $lightBrown !important;
    // border-radius: 25px;
    border: 0.125rem solid $lightBrown;
    border-radius: 5px;
    span{
        background-color: $lightBrown !important;
        padding: 5px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $lightBrown !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: $lightBrown !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}

.pa-11-hotkey_success{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: $orangePink !important;
    // border-radius: 25px;
    border: 0.125rem solid $orangePink;
    border-radius: 5px;
    span{
        background-color: $orangePink !important;
        padding: 5px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $orangePink !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: $orangePink !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}

.pa-12-hotkey_success{
    @extend .submit;
    background-color: $white !important;
    padding: 5px;
    color: $deepPurple !important;
    // border-radius: 25px;
    border: 0.125rem solid $deepPurple;
    border-radius: 5px;
    span{
        background-color: $deepPurple !important;
        padding: 5px;
        color: $white !important;
        border-radius: 25px;
        margin-right:5px;
        &::before {
            content:"- ";
        }
    }
    &:hover,&:focus {
        color: $white !important;
        background: $deepPurple !important;
        box-shadow: 0 0.0625rem 0.125rem 0.125rem $shaded-bg-clr;
        outline: 0px;
        border: 0.125rem solid transparent !important;
        span{
            background-color: $white !important;
            padding: 5px;
            color: $deepPurple !important;
            border-radius: 25px;
            margin-right:5px;
        }
    }
}