.wj-flexgrid {
    max-height: 36rem;
    min-height: 0rem;
    border-radius: 10px;
    //overflow: visible;
    // max-height: 60vh;
    .wj-rowheaders {
        visibility: hidden;
    }
    > div:first-child {
       min-height: 3rem;
    //    overflow: visible !important;
         //wijmo content div min height
    }
    .wj-cells{
        text-transform: uppercase;
       // height: 1rem !important;
    }
    .wj-colheaders{
        .wj-cell {
            &.wj-header{
                text-align: center !important;
            }
            padding: 3px 6px !important;

        }
    }
}
.no-data {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px;
        overflow-x: auto;
        max-height: 0px;
    }
}
.wj-flexgrid>div:first-child {
    // min-height: 9rem  !important;
}
//for Coumn picker
.wj-col-picker-dropdown {
    min-width: 7.14rem;
    min-height: 6.71rem;
    max-height: 56.2rem;
    overflow: auto;
    padding: 0.42rem;
    background-color: $white;
    border: 0.07rem solid $black;
    z-index: 9999999;
    margin-top: -10px;
    box-shadow: 0.35rem 0.35rem 0.35rem rgba(0, 0, 0, 0.3);
    .wj-col-picker-item {
        display: block !important;
        max-width: 100%;
        margin-bottom: 0.35rem !important;
        font-weight: 400 !important;
        input[type="checkbox"] {
            top: 0.14rem;
            position: relative;
        }
    }
}
.wj-cell{
    input[type="checkbox"] {
        display: block !important;
    }
    cursor: pointer;
}

.page--size__pull-right {
    float: right;
}

.fa-cog{
    padding: 0px !important;
    border: unset !important;
}

.wj-listbox.column-picker {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0 10px;
    columns: 1; /* IE fallback */
    padding: 12px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.drug--custom-listbox {
    .wj-listbox.column-picker {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 0 10px;
        columns: 1;
        padding: 20px 12px 0px;
        box-shadow: none !important;
        border: none !important;
    } 

    .wj-listbox .wj-listbox-item > label {
        display: block;
        margin: 0 0 3px 0;
        font-size: 16px;
        font-weight: 400;
        color: #575757 !important;
    }

    .wj-listbox-item {
        box-sizing: border-box;
        padding: 7px 10px;
    }
}
.drug-custom--key {
    padding: 0px 5px;
    height: 35px;
}
.wj-listbox .wj-listbox-item > label {
    display: block;
    margin: 0 0 3px 0;
}
.wj-listbox .wj-listbox-item.wj-state-selected
{
    background: transparent;
    color: inherit;
}
.wj-listbox .wj-listbox-item:hover {
    background: rgba(0,0,0,.05);
}
.wj-listbox .drop-marker {
    position: absolute;
    background: #0085c7;
    opacity: 0.5;
    pointer-events: none;
    z-index: 1000;
}

// wijmo-grids-height-customization
.wjm-grd-custom-height-5 {
    min-height: 5vh !important;
}
.wjm-grd-custom-height-16 {
    min-height: 14vh !important;
}
.wjm-grd-custom-height-19 {
    max-height: 19vh !important;
}
.wjm-grd-custom-height-34 {
    max-height: 34vh !important;
}
.wjm-grd-custom-height-35 {
    max-height: 35vh !important;
}
.wjm-grd-custom-height-36 {
    max-height: 36vh !important;
}
.wjm-grd-custom-height-39 {
    max-height: 39vh !important;
}
.wjm-grd-custom-height-40 {
    max-height: 40vh !important;
}
.wjm-grd-custom-height-41{
    max-height: 41vh !important;
}
.wjm-grd-custom-height-42 {
    max-height: 42vh !important;
}
.wjm-grd-custom-height-transfer-rx{
    max-height: 27vh !important;
    min-height: 7rem !important;
    border-radius: 0px;
    border: none;
}
.wjm-grd-custom-height-22 {
    max-height: 22.5vh  !important;
}
.wjm-grd-custom-height-23-5 {
    max-height: 23.5vh  !important;
}
.wjm-grd-custom-height-29-5 {
    max-height: 29.5vh  !important;
}
.wjm-grd-custom-height-44 {
    max-height: 44vh !important;
}
.wjm-grd-custom-height-46 {
    max-height: 46vh !important;
}
.wjm-grd-custom-height-60 {
    max-height: 60vh !important;
}
.wjm-grd-custom-height-48 {
    max-height: 48vh !important;
}
.wjm-grd-custom-height-50 {
    max-height: 50vh !important;
}
.wjm-grd-custom-height-51 {
    max-height: 51vh !important;
}
.wjm-grd-custom-height-52-0 {
    max-height: 52vh !important;
}
.wjm-grd-custom-height-52 {
    max-height: 52.5vh !important;
}
.wjm-grd-custom-height-53 {
    max-height: 53.5vh !important;
}
.wjm-grd-custom-height-55 {
    max-height: 55vh !important;
}
.wjm-grd-custom-fileGenerator {
    max-height: 43vh !important;
    min-height: 54.5vh !important;
}
.wijm-grid_file {
    max-height: 18rem !important;
    min-height: 4rem !important;
    height: 4rem !important;
}
.wijm--expand {
    max-height: 20rem !important;
    min-height: 6rem !important;
    height: 5rem !important;
}
.wjm-grd-custom-height-56 {
    max-height: 56vh !important;
}
.wjm-grd-custom-height-58 {
    max-height: 58vh !important;
}
.wjm-grd-custom-height-60 {
    max-height: 60vh !important;
}
.wjm-grd-custom-height-62 {
    max-height: 62vh !important;
}
.wjm-grd-custom-height-64 {
    max-height: 64vh !important;
}
.wjm-grd-custom-height-66 {
    max-height: 66vh !important;
}
.wjm-grd-custom-height-74 {
    max-height: 74vh !important;
}
.wjm-grd-custom-height-67 {
    max-height: 67vh  !important;
}
.wjm-grd-custom-min-height-39{
    min-height: 39vh !important;
}
.patient-med-adherenceWjm {
    height: 48rem;
    max-height: 28.1rem;
}
.pat-spec-prescWjm {
    max-height: 52rem
}
.equivalent-drugWJm {
    max-height: 15rem
}
.print-ref-Wijmo {
    max-height:12rem;
}
.print-batch-rx-Wijmo {
    max-height:25rem;
}
.wjm-grd-custom-height-35rem {
    max-height: 35rem !important;
}
.equivalent-drugs-wijmo {
    max-height: 20rem;
}
.fax-batch-rxs-wijmo {
    max-height: 12rem;
}
.add-life-style-wijmo, .add-pat-cat-wijmo {
    max-height: 30rem !important;
}
.presc-rx-history-wijmo {
    max-height: 32rem !important;
}
.existing-patient-family-wijmo {
    height: 26rem;
}
.track-patient-wijmo {
    max-height: 35rem !important;
}
.track-rx-wijmo {
    max-height: 18rem !important;
}
.bucket-logs-wijmo, .bucket-history-wijmo {
    max-height: 45vh;
}
.dur-duplicate-drugs-wijmo {
    max-height: 45rem;
}
.dur-duplicate-drugs-wijmo {
    max-height: 24rem;
}
.adding-wj-height{
 max-height: 46rem !important;
 min-height: 6rem;
 border-radius: unset;
}
.wjm-grd-custom-height-singleitem {
    min-height: 6rem !important;
}
.wjm-grd-custom-height-4rem {
    min-height: 4rem !important;
}
.wj-nodelist .wj-state-empty:nth-child(1) { // to hide the first duplicate child item in every parent item of privilege in add/edit roles popup.
    display: none !important;
}
.no-text-Transform {
    text-transform: none !important;
}

#theGridTallRows.wj-flexgrid .wj-cell {
    padding: 10px;
}
#theGridTallRows.wj-flexgrid  {
    .wj-colheaders{
        .wj-cell {
            &.wj-header{
                text-align: center !important;
                background: #31afe5 !important;
                color: white !important;
            }
        }
    }
    .fa-cog {
        background: unset !important;
        color: #fff;
    }
}
.wj-state-focused .wj-state-multi-selected {
    .actions-plus--icon {
        background-color: #80ADBF;
        color: #fff !important;
    }
}
.wj-group:not(.wj-state-selected):not(.wj-state-multi-selected).whiteColorClass {
    background-color: #fff;
}
.wj-state-multi-selected div:has(> .inputCheck) {
    text-align: center !important;
}
#rowHeightWijmo .wj-flexgrid .wj-cell {
    padding: 5px !important;
}
#inline--suggest--display.wj-flexgrid .wj-cell {
    overflow: visible !important;
    
}
#remittanceId.wj-flexgrid .wj-cell:not(.wj-group):not(.wj-state-selected) {
    padding-left: 3px !important;
}
.circleToggle.wj-btn {
    padding: 0px 4px;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 50% !important;
    // height: 1.5rem;
    // height: 1.8rem !important;
}

button.circleToggle{
    line-height: normal !important;
}

.drugwj-grid .wj-state-focused .wj-state-multi-selected {
    background-color: #DFEAEF !important;
  }

  .rx-transfer-in {
    /* Selected state colors */
        .wj-state-focused .wj-state-multi-selected, .wj-state-active, .wj-state-multi-selected {
            background-color: $white !important;
            color: $black !important;
        }
  }
#InvDates.wj-flexgrid{
    .wj-colheaders{
        .wj-cell {
            &.wj-header{
                height: 8rem !important;
            }
        }
    }
}
#InvSubDates.wj-flexgrid{
    .wj-colheaders{
        .wj-cell {
            &.wj-header{
                height: auto !important;
            }
        }
    }
}
.wjm-grd-custom-minmaxheight-3_20rem {
    min-height: 3rem !important;
    max-height: 15rem !important;
}