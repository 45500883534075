:root {
    --cloud-font-family: "Roboto";
}

$spacer : 8px;
$spacer-16: 16px;
$body-color:#F9F9F9;
$sora-font-family: "Sora", sans-serif;
$inter-font-family: "Roboto", sans-serif;
$google-fonts:'Material Symbols Outlined';
$spc-header-color: #31afe5;
$spc-icons-clr: #858585;
$spc-borders-clr: #D9DADE;
$spc-back-clr: #EEEEF0;
$spc-hist-txt-clr: #5D636B;
$active:#51A351;


*, *:before, *:after {
    box-sizing: border-box;
}

$dash-body-sec: ( 
    'width': 100%,
    'height':100%,
    'grid-width':1689px
);

$grid-col-Bpts: (
    'one-ten': 1 / span 10, 
    'three-ten': 3 / span 10,
    'two-eight': 2 / span 8,
    'two-four': 2 / span 4,
    '8-12': 24px repeat(12,1fr) 24px,
    '12-0': 0px repeat(12, 1fr) 0px,
    '8-10': 0px repeat(10, 1fr) 0px,
    '4-0': 0px repeat(4, 1fr) 0px,
);

$font-sizes: (
    'fs-12': 12px,
    'fs-14': 14px,
    'fs-16': 16px,
    'fs-18': 18px,
    'fs-19': 19px,
    'fs-20': 20px,
    'fs-22': 22px,
    'fs-24': 24px,
);

$font-weights: (
    'fw-3': 300,
    'fw-4': 400,
    'fw-5': 500,
    'fw-6': 600,
    'fw-7': 700
);

/* Secure Patient Communication styles Start */ 
$spc-icons-clr: #858585; 
$spc-back-clr: #EEEEF0;
$spc-chat-hist-clr: #9094A0;
$spacer-16: 16px;

.spc-comm-hdr-sec {
    font-family: var(--cloud-font-family);
    font-size: $spacer-16;
    font-weight: map-get($font-weights, '700'); 
}

.spc-container { 
    display: grid; 
    grid-template-columns: map-get($grid-col-Bpts, '12-0');
    grid-template-areas:
        "spc-header" 
        "spc-sub-header"
        "spc-chat-container";
    column-gap: $spacer; 
    margin: $spacer - 2 auto;
    width: map-get($dash-body-sec, 'grid-width'); 


    .spc-header{ 
        grid-area: spc-header;
        display: grid;
        grid-column: 2 / 14;     
        background-color: $spc-header-color;
        border-top-left-radius: $spacer;
        border-top-right-radius: $spacer; 
        height: 45px; 
        width: 100%;
        span {
            height: inherit;
            margin-left: $spacer-16; 
            display: flex;
            align-content: center;
            align-items: center;
            color: $white;
            font-weight: 700;
        }
    }
    .spc-sub-header{
        grid-template-columns: map-get($grid-col-Bpts, '12-0');
        grid-area: spc-sub-header;
        display: grid;
        grid-column: 2 / 14;
        color: $white;
        height: 72px;
        grid-auto-flow: column;
        background-color:$white;
        .msg {
            border: $spc-borders-clr solid 1px;
        }
        .msg-user-selection {
            border-top-color: $spc-borders-clr;
            border-top-style: solid;
            border-top-width: thin;
            border-right-color: $spc-borders-clr;
            border-right-style: solid;
            border-right-width: thin;
            border-bottom-color: $spc-borders-clr;
            border-bottom-style: solid;
            border-bottom-width: thin;
            .user-hist-search, .user-hist-more{
                span {
                    cursor: pointer;
                }
                img{
                    width: 20px;
                    height: 20px;
                    display: none;
                }
            }
        }
        .msg{
            grid-column: 1 / 5;
            display: grid;
            align-items: center;
            span {
                font-family: $inter-font-family;
                font-size: $spacer-16;
                color: $spc-hist-txt-clr; 
                margin-left: $spacer * 3;
                font-weight: map-get($font-weights, 'fw-6');
            }
        }
        .msg-user-selection{
            grid-column: 5 / 14;
            display: grid;
            align-items: center;
            grid-template-columns: auto 25%;
            span{
                color: $spc-chat-hist-clr;
                font-size: medium;
            }
            .user-selec-img{
                margin-left: $spacer * 4;
                display: flex;
                .profile-pic{
                    img{
                        width: 45px; 
                        height: 45px; 
                        border-radius: 9999px
                    }
                }
                .profile-txt{
                    display: flex;
                    flex-direction: column;
                    justify-content: center; 
                    font-family: $inter-font-family;
                    margin-left: 10px;
                    span:nth-child(1) {
                        color: $spc-hist-txt-clr; 
                        font-size: $spacer-16 - 2; 
                        font-weight: map-get($font-weights, 'fw-6');
                        text-transform: capitalize;
                    }
                    span:nth-child(2) { 
                        font-size: $spacer-16 - 2;
                        font-weight: map-get($font-weights, 'fw-4');
                        display: flex;
                        align-items: center;
                        > div {
                            width: $spacer; 
                            height: $spacer; 
                            position: relative; 
                            background: $active; 
                            border-radius: 20px;
                            margin-right: 4px;
                        }
                    }
                }
            }
            .search-bar {
                display: flex;
                border-radius: 100px;
                border: 1px #DFE4E9 solid;
                background-color: #FFFFFF;
                height: 40px;
                margin: 16px 0 12px 8px;
                padding: 6px 0px 6px 16px;
                align-items: center;
                .spc-inputTxt {
                    flex: 1;
                    border: none;
                    outline: none;
                    background: none;
                    font-size: 13px;
                    color: #858585;
                    padding: 5px;
                    height: 48px;
                    text-transform: lowercase !important;
                }
            }
            .user-hist-search {
                margin-right: 20px;
            }
        }
    }
    .spc-chat-container{
        grid-template-columns: map-get($grid-col-Bpts, '12-0');
        display: grid;
        grid-column: 2 / 14;
        border-bottom-color: $spc-borders-clr;
        border-bottom-style: solid;
        border-bottom-width: thin;
        border-right-color: $spc-borders-clr;
        border-right-style: solid;
        border-right-width: thin;
        border-left-color: $spc-borders-clr;
        border-left-style: solid;
        border-left-width: thin;
        border-bottom-left-radius: 8px 8px;
        border-bottom-right-radius: 8px 8px;
        .msg-user{
            grid-column: 1 / 5;
            border-right-color: #d9dade;
            border-right-style: solid;
            border-right-width: thin;
            background-color:$white;
            border-bottom-left-radius: 8px 8px;
            box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
                .spc-search {
                        display: grid;
                        grid-row: 1;
                        grid-template-columns: auto 68px;
                        grid-template-rows: auto;
                    .search-bar {
                            display: flex;
                            border-radius: 100px;
                            border: 1px #DFE4E9 solid;
                            background-color: $white; 
                            height: 40px;
                            margin: $spacer-16 0 $spacer + 4 $spacer;
                            padding: $spacer - 2 0px $spacer - 2 $spacer + $spacer;
                            align-items: center;
                            span {
                                color: $spc-icons-clr;
                                font-size: medium;
                            }
                        .search-icon {
                            margin-right: 10px;
                            font-size: 13px;
                            color: $spc-icons-clr;
                        }
                        .spc-inputTxt {
                            flex: 1;
                            border: none;
                            outline: none;
                            background: none;
                            font-size: 13px;
                            color: $spc-icons-clr;
                            padding: 5px;
                            height: 48px;
                            text-transform: lowercase !important;
                            &::placeholder {
                                color: #aaa;
                            }
                        }
                    }
                    .spc-filter {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: $spacer;
                        span {
                            color:$spc-chat-hist-clr;
                            margin:0;
                            cursor: pointer;
                        }
                        img {
                            width: 20px;
                            cursor: pointer;
                        }
                    }
                }
            .spc-users-list-scroll {
                height: 630px;
                overflow-y: auto; 
                .spc-users-list {
                    display: grid;
                    overflow-y: auto;
                    .selected{
                        background-color: #ECF5FF;
                    }
                    .spc-user-sec {
                        display: grid;
                        grid-template-columns:max-content 1fr max-content;
                        justify-content: start;
                        align-items: center;
                        font-family: $inter-font-family;
                        margin: $spacer $spacer + 4 0 $spacer;
                        padding: $spacer - 4 $spacer;
                    //  background-color: #ECF5FF;
                        border-radius: $spacer;
                        .profile-pic{
                            img{
                                width: 54px;
                            }
                        }
                        .spc-user-txt {
                            display: grid;
                            margin: 0 $spacer-16 0 $spacer;
                            cursor: pointer;
                            .title{
                                font-size: 14px;
                                font-weight: map-get($font-weights, 'fw-5');
                                text-transform: capitalize;
                            }
                        }
                        .spc-user-txt{
                            .desc{
                                font-size: $spacer + 4;
                                margin-top: $spacer - 4;
                            }
                            .ellipsis-text {
                                display: -webkit-box;
                                -webkit-line-clamp: 2;  
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-height: 3em;  
                                line-height: 1.5em;  
                            } 
                        }
                        .spc-user-act {
                            display: grid; 
                            height: 100%;
                            span{
                                display: flex;
                                font-size: $spacer + 4;
                            }
                            .active-counts{
                                width: 100%;
                                display: flex;
                                justify-content: flex-end;
                                align-items: flex-end;
                                span { 
                                    width: $spacer * 3; 
                                    padding-left: $spacer; 
                                    padding-right: $spacer; 
                                    padding-top: 4px; 
                                    padding-bottom: 4px; 
                                    background: $active; 
                                    border-radius: 20px; 
                                    overflow: hidden; 
                                    justify-content: center; 
                                    align-items: center; 
                                    display: inline-flex;
                                    color:$white;
                                }
                            }
                        }
                    }
                }
            }
        }
        .spc-main {
            display: grid;
            grid-column: 5 / 14;        
            border-radius: $spacer; 
            background-color: $body-color;
            height: 73vh;
            grid-template-rows: auto 1fr auto;
     
            //bottom section 
            .spc-input {
                display: grid;
                grid-row: 3;
                grid-template-columns: auto max-content max-content;
                grid-template-rows: 40px;
                padding: $spacer * 2 100px;
                background-color: $white;
                border-bottom-right-radius:8px 8px;
                .link {
                    display: flex;
                    align-items: center;
                    margin: 0 16px 0 24px;
                    img{
                        width: 20px;
                        height: 20px;
                    }
                }
                .spc-enter {
                    display: flex;
                    align-items: center;
                    background-color: $spc-back-clr;
                    padding: 0; 
                    background: $white; 
                    border-radius: 100px; 
                    border: 1px #DFE4E9 solid;
                    .spc-inputTxt {
                        flex: 1;
                        border: none;
                        outline: none;
                        background: none;
                        font-size: 14px;
                        color: $spc-hist-txt-clr;
                        padding: 0 0 0 $spacer-16;
                        font-weight:  map-get($font-weights, 'fw-3');
                        font-size: 14px;
                        font-family: $inter-font-family;
                        text-transform: none !important;
                        &::placeholder {
                            color: #aaa; 
                        }
                    }
                    .spc-inputTxt::placeholder {
                        color: gray;
                    }
                    .spc-inputTxt.error-placeholder::placeholder {
                        color: #f6251a; 
                    }
                    &::placeholder { 
                        margin-left: $spacer-16;
                    }
                    
                    span {
                        color: $spc-icons-clr;
                        padding-left: $spacer;
                        cursor: pointer;
                    }
                    span.camera {
                        padding: $spacer * 2 $spacer * 2;
                    }
                }
                .send-button{
                    background-image: url('/assets/images/send.svg');
                    background-size: 20px 20px; 
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    background: #51A351; 
                    border-radius: 100px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    width: 40px;  
                    height: 40px; 
                    cursor: pointer;
                    margin-left: $spacer * 2;
                    svg {
                        fill: #FFF !important;
                        cursor: pointer;
                        width: 20px;
                        position: relative;
                    }
                }
            }
            .spc-history {
                font-family: $inter-font-family;
                .spc-from-pharmacist .spc-your-pharmacist{
                    text-transform: lowercase;  
                    text-transform: capitalize; 
                }
                .spc-from-pharmacist { 
                        .spc-your-pharmacist, .spc-recieved-date-time {
                            margin: 4px 0 4px 0;
                            font-size: 10px;
                        }
                        margin:0 129px; 
                    span {
                        font-size: 13px;
                        width: auto; 
                        height: auto; 
                        padding-left: $spacer + 4; 
                        padding-right: $spacer + 4; 
                        padding-top: $spacer; 
                        padding-bottom: $spacer; 
                        background: $spc-back-clr; 
                        border-top-left-radius: $spacer-16; 
                        border-top-right-radius: $spacer-16; 
                        border-bottom-right-radius: $spacer-16; 
                        border-bottom-left-radius: $spacer - 4;
                       /* flex-direction: column;*/ 
                        justify-content: center; 
                        align-items: flex-end; 
                        display: inline-flex;
                        line-height: 1.49;
                        word-break: break-all;
                        > a { 
                            padding-left: $spacer - 3;
                        }
                    }
                    .spc-pharmacist-msg {
                        display: grid;
                        justify-content: start;
                        max-width: 80%;
                    }
                }
                .spc-frm-you .spc-frm-patient{
                        text-transform: lowercase;  
                        text-transform: capitalize; 
                }
                .spc-frm-you {
                    margin:0 120px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .spc-frm-patient, .spc-sent-date-time {
                        margin: 4px 0 4px 0;
                        font-size: 10px;
                    }
                    .spc-frm-msg {
                        span{
                            color: $white;
                            font-size: 13px;
                            width: auto;
                            height: auto; 
                            padding-left: $spacer + 4; 
                            padding-right: $spacer + 4; 
                            padding-top: $spacer; 
                            padding-bottom: $spacer; 
                            right: 0px;
                            background: #31AFE5; 
                            border-top-left-radius: $spacer-16;
                            border-top-right-radius: $spacer-16; 
                            border-bottom-right-radius: $spacer - 4; 
                            border-bottom-left-radius: $spacer-16;
                            /*flex-direction: column;
                            justify-content: flex-end; 
                            align-items: flex-end; 
                            display: inline-flex; */
                            line-height: 1.49;
                            word-break: break-all;
                            > a {
                                    color: $white;
                                    padding-left: $spacer - 3;
                                    text-decoration: underline;
                            }
                        }
                    }
                    .spc-sent-date-time {
                        display: flex;
                        align-items: center;
                        img{
                            font-size: $spacer-16 + 2;
                            margin-left: 5px;
                        }
                        .tick-icon {
                            position: relative;
                            width: 14px;
                            height: 15px;
                            margin-left: 2px;
                        }
                        .comm-check-icon {
                            position: relative;
                            width: 16px;
                            height: 16px;
                            margin-left: 2px;
                        }
                    }
                    .spc-frm-msg {
                        display: grid;
                        justify-content: end;
                        max-width: 80%;
                    }
                }
            }
            .spc-history {
                overflow-y: hidden;
                grid-row: 2; 
            }
            .chat-history{
                overflow-y: auto;
                height: 620px;
                .date-header {
                    display: flex;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    justify-content: center;
                }
                .date-header > span {
                    display: inline-flex;
                    align-items: center;
                    background-color: #e4e4e9;
                    padding: 1px 5px;
                    border-radius: 8px;
                    border: #ccc solid 1px;
                    position: relative;
                    display: inline-block;
                    font-size: 12px;
                }
                .date-header > span:before,
                .date-header > span:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    width: 400px;
                    height: 1px;
                    background: #e4e4e9;
                }
                .date-header > span:before {
                    right: 100%;
                    margin-right: 15px;
                }
                .date-header > span:after {
                    left: 100%;
                    margin-left: 15px;
                }
            }
        }
    }

    .no-matches {
        text-align: center; 
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $spc-icons-clr;
    }
}

@media (max-width: 1689px) {
    .spc-container{ 
        width: 100%;
    }
}

/* Tablet view */
@media screen and (max-width: 1023px) {
    .spc-container {
        grid-template-columns: map-get($grid-col-Bpts, '8-8');
        column-gap: $spacer * 2;
        grid-column: 2 / span 8;

        .spc-main {
            grid-column: 2 / span 8;
        }
    }
}
/* Mobile view */
@media screen and (max-width: 720px) {
    .spc-container {
        grid-template-columns: map-get($grid-col-Bpts, '0-4');
        column-gap: $spacer * 2;
        
        .spc-main {
            grid-column: 2 / span 4;
            height: 100vh;
        }
        .spc-main {
            grid-column: 2 / span 4;
            margin-top: 23px;
            box-shadow:none;
            border:none;
            border-radius:0px;
            .spc-search {
                border-bottom-color:$white;
                border-bottom-style:none;
                border-bottom-width: 0;
                .search-bar { 
                    margin: 12px 0 12px 0;
                    padding: 0px 0 0px 16px; 
                }
            }
            .spc-history { 
                .spc-from-pharmacist{
                    margin: 0 0;
                    .spc-pharmacist-msg { 
                        max-width: 90%;
                    }
                }
                .spc-frm-you{
                    margin: 0 0;
                    .spc-frm-msg { 
                        max-width: 90%;
                    }
                }
            }
            .spc-input {
                padding:0px;
                bottom: 16px;
                position: sticky;
                grid-template-columns: auto 12.5%;
                .spc-enter {
                    width: 100%;
                }
                .spc-send {
                    margin-left: 8px;
                }
            }
        }
        
    }
}


.isCloudApp{
        .spc-container { 
            display: grid; 
            grid-template-columns: map-get($grid-col-Bpts, '4-0');
            width: 100%;
            column-gap: 0;
            margin: 0;
            .spc-header {
                grid-column: 1 / 6;
                height: 40px;
            }
        }

        .spc-container .spc-sub-header {
            grid-template-columns: 0px repeat(4, 1fr) 0px;
            grid-area: spc-sub-header;
            display: grid;
            grid-column: 1 / 6;
            .msg{
                display: none;
            }
            .msg-user-selection {
                grid-column: 1 / 6;
                border-left-color: #D9DADE;
                border-left-style: solid;
                border-left-width: thin;
            }
        }
        
        .spc-container .spc-chat-container {
            grid-template-columns: 0px repeat(4, 1fr) 0px;
            display: grid;
            grid-column: 1/6;
            .msg-user{
                display: none;
            }
            .spc-main { 
                grid-column: 1 / 6;
                border-radius: 8px;
                height: 45.1vh;
                .spc-history {
                    overflow-y: hidden;
                    grid-row: 2;
                    .spc-frm-you {
                        margin:0 25px; 
                    }
                    .spc-from-pharmacist { 
                        margin:0 39px; 
                    }
                }
                .chat-history { 
                    height: 365px;
                }
            }
            .spc-input {
                padding: 16px 30px;
                border-bottom-left-radius: 10px 10px; 
            }
        }

        .spc-container .spc-chat-container { 
            border-bottom-color: #e5e5e5;
            border-bottom-style: solid;
            border-bottom-width: thin;
            border-right-color: #e5e5e5;
            border-right-style: solid;
            border-right-width: thin;
            border-left-color: #e5e5e5;
            border-left-style: solid;
            border-left-width: thin;
            border-bottom-left-radius: 10px 10px;   
            border-bottom-right-radius: 10px 10px;
            box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
        }
}
/* Secure Patient Communication styles End */ 