.login {
    border: 2px solid white;
    box-shadow: 10px -11px 5px 12px white;
    height: 70px;
}
.welcome_eprimerx {
    margin-top: 6rem;
    padding-left: 12rem;
    color: $light-gray
}

.welcome_eprimerx2 {
    font-size: 3rem;
    margin-bottom: 1.5rem !important;
    color: $light-gray
}
.eprimerx-text {
    max-width: 98rem;
    margin-top: 37px;
}
.extra_info {
    color: $light-gray;
    border: 0.125rem solid $light-gray;
}
.alert_css {
    background: rgba(0,0,0,0.3);
    z-index: 1060;
    position: fixed;
}
.help_preferred_drug {
    border-left: 25px solid $dark-brown;
    height: 26px;
}
.acc_set_cntrl {
    left:1rem;
    top:20px;
}
.sms_style {
    position: absolute;
    left: 3.4rem;
    top: 1px;
    font-size: large;
}
.msg_alert {
    font-size: 16px;
    color: rgb(247, 148,29)
}
.edit_disable_sms {
    font-size: 2rem;
    color: gray;
    cursor: not-allowed;
}
.succes_report {
    width:2.1rem;
    margin-left:2.5rem
}
.zero_reprot {
    width:1.7rem;
    margin-left:2.7rem
}
.zeroReport_cntrl_rx {
    width:1.8rem;
    margin-left:0.2rem;
    margin-top:-7px
}
.compound--drug-cat {
    color: $white;
    top: -2px;
    right: 28px;
    height: 16px;
    width: 16px;
    background: $orange;
    border-radius: 50%;
    font-weight: bolder;
    position: absolute;
    text-align: center;
    padding-right: 10px !important;
    padding-left: 5px !important;
    font-size:12px !important;
    top: 0.3rem;
}
.drug_description_tooltip {
    margin-left:0.5rem;
    padding: 0 0.5rem;
    font-weight: bold;
    color: $white;
    height: 23px;
    width: 23px;
    text-align: center;
    font-size: 1.2rem;
    background: $orange;
    border-radius: 50%;
}
.drug_description_tooltip_OnImg {
    margin-left:0.5rem;
    padding: 0 0.5rem;
    font-weight: bold;
    color: $white;
    height: 72px;
    width: 23px;
    text-align: center;
    font-size: 1.2rem;
    border-radius: 50%;
    position: absolute;
    top: 1rem !important;
}
.nrx-actions-buttons {
    width: 5rem;
    padding-bottom: 3px;
    margin-right: 0px;
}
.benefit_check_instructTooltip {
    margin-left:0.5rem;
    padding: 0 0.5rem;
    font-weight: bold;
    color: $white;
    height: 23px;
    width: 23px;
    font-size: 1.2rem;
    background: $orange;
    border-radius: 50%; width:50px
}
.erx_result_class {
    top: 0.75rem;
    left: -2.7rem;
    margin-bottom: 0.5rem;
}
.label_Design_edit_icon {
    height: 38px;
    width: 38px;
    background-color: $orange;
    border-radius: 50%;
    text-align: center;
    margin-top: 0.2rem;
    margin-right: 2rem;
    cursor: pointer;
}
.red_flag_display {
    font-size: 82% !important;
    position: absolute !important;
    left: 6rem !important;
    color: $white !important;
}
.hourglass{
    font-size:  19px !important;
    color: #efa00e !important;
    margin-top: 1px !important;
    margin-left: 6px;
}

.medication_Category {
    color: $white;
    top: 5px;
    right: 43px;
    height: 16px;
    width: 16px !important;
    font-size: 12px;
    background: $orange;
    border-radius: 50%;
    padding-left: 5px !important;
    font-weight: bolder;
    position: absolute;
    text-align: center;
    padding-right: 6px;
}
.review_x_days {
    margin:-17px 0px 0px auto;
    max-width: 7rem !important;
}
.pdc_green {
    width: 55px;
    border-radius: 75px 75px;
    height: 7px;
    border-image-source: linear-gradient(268.52deg, #AEE38D -1.76%, #21BA67 92.86%);
    background: linear-gradient(268.52deg, #AEE38D -1.76%, #21BA67 92.86%);
    display: inline-block;
}

.pdc_red {
    width: 55px;
    border-radius: 75px 75px;
    height: 7px;
    background: linear-gradient(268.51deg, #F6921E -1.77%, #F85B04 92.99%);
    border-image-source: linear-gradient(268.51deg, #F6921E -1.77%, #F85B04 92.99%);
    display: inline-block;
}

.pdc_yellow {
    width: 55px;
    border-radius: 75px 75px;
    height: 7px;
    background: linear-gradient(268.5deg, #F8B404 -3.3%, #FFE660 93.17%);
    border-image-source: linear-gradient(268.5deg, #F8B404 -3.3%, #FFE660 93.17%);
    display: inline-block;
}

.pdc_orange_yellow {
    width: 55px;
    border-radius: 75px 75px;
    height: 7px;
    background: linear-gradient(268.5deg, #FFE660 -3.3%, #F8B404 93.17%);
    border-image-source: linear-gradient(268.5deg, #FFE660 -3.3%, #F8B404 93.17%);
    display: inline-block;


}
.padding_inactivity_timeout {
    .form-control-settings {
        height: 32px !important;
    }
    padding-right: 0rem !important;
    padding-left: 0rem !important;
}
.input-label-color {
    font-size: 1.1rem;
    .control-label {
        line-height: 2.3;
    span {
        color: $lightSkyBlue !important;
        .help--text__danger {
            color: $danger !important;
        }
    }
}
}
.ng-select-border {
     .ng-select-container, .rx--select {
    border-color: #99aebe !important;
    border-width: 1px !important;
    color: #5d636b !important;
     }
     .ng-value {
        color: $text-color-black !important;
     }
}
.pharmacySettings-date_fields{
    .custom--date {
        margin-top: -23px !important;
    }
}


    .table-striped {
        thead {
            tr {
                .top-left {
                    border-top-left-radius: 7px !important;
                }
            }
        }
    }
    .table-striped {
        thead {
            tr {
                .top-right {
                    border-top-right-radius: 7px !important;
                }

                }
            }
        }

.table-striped  {
    tbody {
        tr {
           .border-color-table {
            border: 1px solid #c3cdd5 !important;
           }
        }
    }
}
.table-striped {
    tbody {
        .background-color-odd:nth-of-type(odd)  {
            background-color: #F1F8FE !important;
        }
    }
}
.table-striped {
    tbody {
        .background-color-even:nth-of-type(even)  {
            background: #ECF2F8 !important;
        }
    }
}
.Acc_Set_dropdown_width {
    .ng-select-container {
        width: 112% !important;
        left:-2rem !important
    }
}
.acc-set-adverse-drug-dropdown {
    .ng-select-container {
        width: 124% !important;
        left:0rem !important
    }
}
.acc-set-adverse-incidence {
    .ng-select-container {
        width: 117% !important;
        left:-3rem !important
    }
}

.primePos_tooltip {
    @extend .medication_Category;
        top:16px !important;
        line-height: 1rem;
        left:15.5rem !important;
        right:0px !important;
}
.input_rx_tab {
    .custom--input {
        width: 17.3rem;
    }
}
.input_rx_tab_refill_due_threshold {
    .custom--input {
        width: 8rem;
        left:-1rem;
    }
}
.preferred_Drug_Dropdown {
    .ng-select-container {
        width:11.5rem !important;
    }
}

.eligibility_Table{
    table{
       width: 100%;
      }
      thead{
        tr{
            border-bottom: 1px solid lightgray;
        }
      }
        tbody{
            tr{
                border-bottom: 1px solid lightgray;
            }
        }
}
.table-bordered {
    tbody {
        tr {
            .bottom-left {
                border-bottom-left-radius: 10px !important;
            }
        }
    }
}
.table-bordered {
    tbody {
        tr {
            .bottom-right {
                border-bottom-right-radius: 10px !important;
            }
        }
    }
}
.table-bordered {
    thead {
        tr {
            .top-left {
                border-top-left-radius: 10px !important;
            }
        }
    }
}
.table-bordered {
    thead {
        tr {
            .top-right {
                border-top-right-radius: 10px !important;
            }

            }
        }
    }
.rxNotes--color{
    color: white;
}
.notesLog--rph{
    text-align: center;
    color: white;
    font-size: 15px;
    background-color: $primary;
}
.form-check-input-notes {
    display: block !important;
    height: 1.4rem;
    width: 1.4rem;
    margin-top: 9px;
}
.table-rph{
    background-color: aliceblue;
    border-radius: 10px !important;
}
.border-td{
    border: 1px solid rgb(237, 224, 224) !important;
}
.notesLog--rph th{
    border: 1px solid rgb(237, 224, 224) !important;
}


.background_organge{
   background-color: #f7941d  !important;
}
.annonations_sticky:before {
	content:"";
  display:block;
  position:absolute;
  width:20px;
  height:25px;
  background:#ffa;
  box-shadow:
    3px -2px 10px rgba(0,0,0,0.2),
    inset 15px -15px 15px rgba(0,0,0,0.3);
  left:0;
  bottom:0;
  z-index:2;
  transform:skewX(25deg);

}
.ellipsis_header{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}