
//For Modal
.max-modal-lg-size .modal-dialog {
    max-width: 1651.56px;
    height: 746px;
    margin-left: 11%;
    margin-top: 3%;
 }

.stepper1{
    width: 249px;
    height: 1px;
    vertical-align: middle;
    position: relative;
    left: 27rem;
    top: -13px;
}
.mainStepper{
    width: 249px;
    height: 1px;
    vertical-align: middle;
    position: relative;
    top: -13px;
}
.stepper1_mark_as_transferIn{
        @extend .mainStepper;
        left: 27rem;
}
.stepper2_mark_as_transferIn{
    @extend .mainStepper;
    left: 14rem;
}

.stepper2{
    width: 249px;
    height: 1px;
    vertical-align: middle;
    position: relative;
    left: 16rem;
    top: -13px;
}

.pharmacy-selection{
    .form-group {
            margin-bottom: 0px !important;
    }
    .custom--input {
        height: 3.25rem !important; 
    }
}
.eprx--block__content-card {
    border-radius: 8px;
    .eprx--block__content-card-header {
        background-color: #31afe5;
        
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        color: $white;
        vertical-align: middle;
        height: 35px;
        padding: 8px 0px 8px 4px;
    }
    .eprx--block__content-body-eRx_transferReq {
            border: 1px solid #DBDBDB;
            background-color: #FFFFFF;
            height: 515px !important;
            box-shadow: 0 0.21rem 0.5rem 0 rgba(0, 0, 0, 0.06);
            height: auto;
            padding: 0.7rem;
            border-bottom-right-radius: 8px;
             border-bottom-left-radius: 8px;
    }
    label {
        font-size: 13px !important;
    }
}
.row-space{
    padding-top: $space * 2 !important;
}
.row-space-transfer-in{
    padding-top: $space  !important;
}
.row-space1{
    padding-top: $space  !important;
}
.eprx-transfer-req {
   
    .seconday-search--grid{
        .input-group{
            margin-top: 0px !important;
        }
    }
    .search--by__radiobtn{
        .form-group {
            margin-bottom: 0.1rem !important;
        }
    }
    .erx--body .seconday-search--grid .input-group .search--input__box
    {
        height: 3.25rem !important; 
    }
    .eprx-global-patient-search {
        .seconday-search--grid {
            height: 45px;
        }
    }
    .eprx-selected_details  {
        background: $green !important;
        width: 40px;
        height: 40px;
        padding: 12px;
        border-radius: 50%;
        font-size: $space * 2;
        color: $white;
    }
    .eprx-nonselected_details  {
        background: #d6d9dd !important ;
        width: 40px;
        height: 40px;
        padding: 12px;
        border-radius: 50%;
        font-size: $space * 2;
    }
    .eprx-eRx_transferReq {
        padding-bottom: $space * 2;
        padding-top: $space - 4;
    }
    .eRx-transfer_typedetails {
        div:first-child {
            font-size: $space * 2 - 3;
        }
        div:last-child {
            font-size: $space * 2 - 4;
        }
    }
    .eprx-eRx_transferReq-border-select{
        border-top: 2px solid $green  !important;
    }
    .eprx-eRx_transferReq-border-unselect{
        border-top: 2px solid #b4b7bb   !important;
    }
   
    .label--data{
        font-size: $space * 2 - 4;
    }
    .fa-house-medical-thin{
        font-size: 6rem !important;
        color: gainsboro;
    } 
    .fa-house-medical-circle-check-regular{
        font-size: 3rem !important;
        color: $green;
    }
}
.pharmacy_dropdown {
    .inactive {
        min-width: 6.5rem !important;
        height: 3.175rem !important;
    }
    .ng-select.ng-select-single .ng-select-container {
        height: 3.25rem !important; 
    }
        .ng-select .ng-dropdown-panel
    {
        width: 45rem !important;
    }
    .form-group{
            margin-bottom: 0px !important;
    }
    .custom--input {
        height: 0px !important;
    }
}
.pt-6 {
    padding-top: 4.5rem !important;
}
.pt-24{
    padding-top: $space * 3;
}
.mt-20 {
    margin-top: $space * 2 + 4;
}
.max-width{
    .ng-dropdown-panel .ng-dropdown-header{
        width: 1000px !important;
    }
}
.eprx-form-group {
    .form-group {
        margin-top: $space * 2 !important;
        margin-bottom: 0px !important;
    }
}
.eprx-border{
    border: 1px solid $borderColor;
}
.modal-body-heading{
    font-size: $space * 2 !important;
}

.eprx-row{
    display: flex;
}
.plr-16{
    padding-right: $space * 2;
    padding-left:  $space * 2;
}
.eprx-col-6{
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    @extend .plr-16;
}

.eprx-col-12{
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    @extend .plr-16;
}
.eprx-label{
    color:$cyan !important;
    margin-bottom: 0px !important;
    font-weight: bold;
    font-family: "Roboto";
}
.eprx-card {
    @extend .eprx-border;
    border-radius: $space;
    @extend .plr-16;
    height: 324px;
    div.eprx-card-header {
        margin-top: $space * 3;
        font-size: $space * 2;
        @extend .eprx-label
    }
    div.eprx-card-label--data {
        margin-top: $space;
    }
    div.eprx-card-label--data:last-child  {
        margin-top: $space;
    }
}
.fa-info-circle{
    color: $orange;
    font-size: 20px !important;
}

.angular-editor-wrapper{
    .angular-editor-textarea:focus-visible {
        outline: aqua;
        border: 1px solid #31afe5 !important;
    }
}
.notes_export{
    position: relative;
    top: 3px;
    left: 3px;
}
.eprx-font-16{
    font-size: $space * 2 !important;
}

.notes_icon{
    font-size: 20px;
margin-left: 5px;
margin-top: 2px;

}
.height_250{
    height: 250px !important;
  }
  
.fa-notes-filled{
@extend .notes_icon;
color: $primary !important;
}
.fa-notes-line{
    @extend .notes_icon;
}
.ngb-typeahead-window {
    display: block !important;
  }
  .height_300{
    height: 314px !important;
  }
  .cards_height_transferIn{
    height: 282px !important;
  }