.dropdown-item {
    border-width: 0.07rem;
    border-color: $Mirage;
    padding: 0px;
    border: unset;
    .card {
        background-color: $white;
        padding: 0.42rem 1.71rem 0.42rem 2rem;
        font-family: $default-font-family;
        margin: 0.2rem 0.08rem;
        border: 0.07rem solid #e5e5e5;
        height: auto;
        border-radius: 10px !important;
        .card__subTit--head {
            color: solid-lighten($Mirage, 0.8);
            font-weight: 500;
        }
        .card__subTit--title {
            padding-left: 0.42rem;
            font-weight: 500;
            text-transform: uppercase;
        }
        color: $dflt-txt-clr;
        &:hover,
        &:focus,
        &:active {
            box-shadow: 0.3rem 0.2rem 0.1rem 0 rgba(0, 0, 0, 0.1);
        }
        .drug-row-no {
            font-family: $tertiary-font-family;
            position: absolute;
            left: 3px;
        }
        .drug-price-notExist {
            height: 20px;
            width: 20px;
            background-color: #bbb;
            border-radius: 50%;
            display: inline-block;
            margin-left: auto;
        }
    }
    .patient-card,
    .prescriber-card,
    .drug-card {
        &.card {
            &:hover {
                background: solid-lighten($light-blue, 0.9946);
                // border: 0.05125rem solid $base;
            }
        }
    }
    .prescriber--icon {
        .doc--icon {
            font-size: 2.14rem;
            color: $black;
            margin-top: 0.71rem;
            background: $white;
            padding: 0.71rem;
            border-radius: 50%;
            color: solid-lighten($orange, 0.9946);
        }
    }
    &.active {
        .card {
            box-shadow: 0.3rem 0.2rem 0.1rem 0 rgba(0, 0, 0, 0.1);
            background: solid-lighten($light-blue, 0.82);
            // border: 0.05125rem solid $base;
        }
        // background: unset;
        outline: none;
        color: $dflt-txt-clr;
    }

    // .drug--grid {
    //     .card {
    //         &:hover,
    //         &:focus,
    //         &:active {
    //             box-shadow: 0px 0px 9px 2px #00a2ff;
    //             border-right: unset;
    //             border-top: unset;
    //             border-bottom: unset;
    //         }
    //     }
    //     .drug-card {
    //         &.card {
    //             &:hover {
    //                 border: 1px solid #00a2ff;
    //             }
    //         }
    //     }
    //     &.active {
    //         .card {
    //             box-shadow: 0px 0px 9px 2px #00a2ff;
    //             border-right: unset;
    //             border-top: unset;
    //             border-bottom: unset;
    //         }
    //     }
    // }
}

.drug-card-red {
    background: #ff7770 !important;
}

.equDrugCard-color {
    background: #bd362f !important;
    color: #ECF0F5 !important;
}

.drug-card-blue {
    border-left: 5px solid $primary !important;
}
.drug-card-brown {
    border-left: 5px solid $brown !important;
}
.drug-card-green_bar {
    border-left: 5px solid rgb(8, 111, 27) !important;
}
.drug-card-green {
    background: #86e096 !important;
}

.noCursor{
    .dropdown-item{
        cursor: none !important;
    }
}

.addCursor{
    .dropdown-item{
        cursor: pointer !important;
    }
}
