.font_weight_bolder {
    font-weight :bolder;
}
.font_weight_250{
    font-weight: 250 !important;
}
.font_weight_300 {
    font-weight: 300 !important;
}
.font_weight_500 {
    font-weight: 500 !important;
}
.font_weight_600 {
    font-weight: 600;
}
.font_weight_700 {
    font-weight: 700;
}
.font_size_medium{
    font-size: medium
}
.font_weight_normal{
    font-weight: normal !important;
}
.font_size_larger {
    font-size: larger;
}
.font_size_large {
    font-size: large;
}
.font_size_0_9rem {
    font-size: 0.9rem
}
.font_size_1_1rem {
    font-size: 1.1rem
}
.font_size_1_14rem {
    font-size: 1.14rem !important
}
.font_size_1_2rem {
    font-size: 1.2rem !important
}
.font_size_1_25rem {
    font-size: 1.25rem
}
.font_size_1_3rem {
    font-size: 1.3rem !important;
}
.font_size_1_32rem {
    font-size: 1.32rem !important
}
.font_size_1_4rem {
    font-size: 1.4rem !important;
}
.font_size_1_5rem {
    font-size: 1.5rem !important;
}
.font_size_1_7rem {
    font-size: 1.7rem;
}
.font_size_1_8rem {
    font-size: 1.8rem !important;
}
.font_size_1_9rem {
    font-size: 1.9rem !important;
}
.font_size_2rem {
    font-size: 2rem !important;
}
.font_size_2_5rem {
    font-size: 2.5rem;
}
.font_size_5rem {
    font-size: 5rem;
}
.font_size_3rem {
    font-size: 3rem;
}
.font_size_10px {
    font-size: 10px
}
.font_size_15px {
    font-size: 15px !important
}
.font_size_18px{
    font-size: 18px
}
.font_size_22_px{
    font-size: 22px
}

.font_size_27px {
    font-size: 27px
}
.font_size_35px {
    font-size: 35px
}
.overflow_y_scroll {
    overflow-y: scroll;
}
.overflow_y_hidden {
    overflow-y: hidden;
}
.overflow_y_auto {
    overflow-y: auto;
}
.overflow_x_hidden {
    overflow-x: hidden !important;
}
.overflow_x_scroll{
    overflow-x: scroll;
}
.overflow_scroll{
    overflow: scroll
}
.overflow-auto{
    overflow: auto
}
.overflow_unset {
    overflow: unset;
}
.overflow_hidden{
    overflow: hidden
}
.overflow_visible{
    overflow: visible !important;
}

//top
.top_2px {
    top:2px
}
.top_n1_5px {
    top: -1.5px
}
.top_n0_4px {
    top: -0.4rem !important
}
.top_n2px {
    top: -2px
}
.top_0rem{
    top:0rem !important;
}
.top_1rem{
    top:1rem;
}
.top_4_5rem{
    top:4.5rem;
}
.top_n1rem{
    top:-1rem;
}
.top_0_31rem {
    top: 0.31rem
}
.top_0_5rem {
    top: 0.5rem
}
.top_0_7rem {
    top: 0.7rem
}
.top_1rem{
    top:1rem;
}
.top_1_5rem{
    top:1.5rem;
}
.top_2rem{
    top:2rem !important;
}
.top_2_5rem{
    top:2.5rem;
}
.top_n2rem {
    top:-2rem
}
.top_3rem{
    top: 3rem;
}
.top_3_2rem{
    top: 3.2rem;
}
.top_3_5rem{
    top: 3.5rem;
}
.top_n3rem {
    top:-3rem
}
.top_n6rem{
    top: -6rem;
}
.top_8px {
    top:8px
}
.top_10px {
    top:10px
}
.top_n5px {
    top:-5px
}
.top_11px {
    top: 11px
}
.top_14px {
    top: 14px;
}
.top_n65px {
    top:-65px
}
.top_n2rem {
    top: -2.0rem;
}
.top_n19px{
    top: -19px
}
.top_25px {
    top : 25px
}
.top_27_6px {
    top : 27.6px
}
.top_negetive_99999_px{
    top: -9999px
}
.top_n21px {
    top: -21px;
}
.top_n7px {
    top: -7px;
}

//background

.background_white {
    background-color: $white;
}
.background-White{
    background: $white;
}
.background_primary {
    background: $primary
}
.background_orange {
    background: $orange
}
.background_red {
    background: $red;
}
.background_grey {
    background: #B8CBCF;
}
.background_antiquewhite {
    background-color: $antiquewhite;
}
.background_offwhite {
    background-color: $off-white;
}
.background_sky_Blue {
    background-color: $sky-blue
}
.background_orange_RGB{
    background: rgb(247, 148,29)
}
.background_light-gray{
    background-color :$light-gray
}
.background_Red {
    background-color: $red
}
.background_color_00a65a {
    background-color: #00a65a;
}
.background_color_lightPink {
    background-color: $light-pink;
}
.background-color-lightestGray{
    background-color: $lightestGray;
}
.background-color-transparent {
    background-color: transparent;
}
.background-color_alice--blue{
    background-color : #f8fafe !important;
}
.background-color-lightOrange {
    background-color : #fdf5ea !important;
}
.background-color-lightgreen {
    background-color : #edf7ee !important;
}
.background-lightBlue {
    background: #EFF7F9 !important;
}
.background-lightGrey {
    background: #1253621A !important;
}
.background-common--blue {
    background: #31afe5;
}
//padding
.padding_1px{
    padding: 1px;
}
.padding_0_714_rem{
    padding: 0.714rem
}
.padding_0_4rem {
    padding:0.4rem
}
.padding_0_5rem {
    padding: 0.5rem;
}
.padding_0_714rem {
    padding: 0.714rem;
}
.padding_1rem {
    padding: 1rem;
}
.padding_1_428rem {
    padding: 1.428rem;
}
.padding_2rem {
    padding: 2rem;
}
.padding_3px {
    padding:3px
}
.padding_5px {
    padding: 5px;
}
.padding_1_5rem {
    padding: 1.5rem !important;
}
.padding_2rem {
    padding: 2rem !important;
}
.padding_1_2_rem {
    padding:1.2rem;
}
.padding_1_428_rem{
    padding: 1.428rem
}
.padding_1_3_rem {
    padding: 1rem 3rem;
}
.padding_0_1_rem {
    padding: 0 1rem;
}
.padding_0_0_4_rem {
    padding: 0 0.4rem;
}
.padding_0_0_0_1rem {
    padding: 0 0 0 1rem;
}
.padding_1_5rem_0_1rem_0_8rem {
    padding: 1.5rem 0 1rem 0.8rem
}
.padding_7rem_0rem_3rem_0rem {
    padding: 7rem 0rem 3rem 0rem;
}
.padding_6px_1px_5px_4px {
    padding: 6px 1px 5px 4px !important;
}
.padding_25px_1px_14px_6px{
    padding: 25px 1px 14px 6px;
}
.padding_7px_5px_3px_5px {
    padding: 7px 5px 3px 5px;
}
.padding_19px_1px_1px_1px {
    padding: 19px 1px 1px 1px;
}
.padding_0_7_px {
    padding: 0px 7px !important;
}
.padding_2rem_0rem_0rem_3rem {
    padding: 2rem 0rem 0rem 3rem !important;
}
.padding_2rem_1rem_0rem_2rem {
    padding: 2rem 1rem 0rem 2rem !important;
}
.padding_1rem_1rem_0rem_2rem {
    padding: 1rem 1rem 0rem 2rem !important;
}
.padding_2px_0px_0px_0px {
    padding: 2px 0px 0px 0px !important;
}

//margin-top
.margin_top_1px {
    margin-top:1px;
}
.margin_top_2px {
    margin-top:2px;
}
.margin_top_0_1rem {
    margin-top: 0.1rem;
}
.margin_top_0_2rem {
    margin-top: 0.2rem;
}
.margin_top_0_3rem {
    margin-top: 0.3rem;
}
.margin_top_0_35rem {
    margin-top: 0.35rem;
}
.margin_top_0_5rem {
    margin-top: 0.5rem !important;
}
.margin_top_0_7rem {
    margin-top: 0.7rem;
}
.margin_top_0_8rem{
    margin-top: 0.8rem
}
.margin_top_0_9rem{
    margin-top: 0.9rem
}
.margin-top-0_714rem{
    margin-top: 0.714rem
}
.margin_top_0_75rem{
    margin-top: 0.75rem
}
.margin_top_2rem {
    margin-top: 2rem;
}
.margin_top_2_5rem {
    margin-top: 2.5rem;
}
.margin_top_1rem {
    margin-top: 1rem !important;
}
.margin_top_1_3rem {
    margin-top: 1.3rem
}
.margin_top_1_4rem {
    margin-top: 1.4rem
}
.margin_top_1_5rem{
    margin-top: 1.5rem
}
.margin_top_1_7rem {
    margin-top: 1.7rem;
}
.margin_top_1_8rem {
    margin-top: 1.8rem;
}
.margin_top_2_3rem {
    margin-top: 2.3rem !important;
}
.margin_top_2_1rem {
    margin-top: 2.1rem
}
.margin_top_2_5rem {
    margin-top: 2.5rem;
}
.margin_top_3rem {
    margin-top: 3rem !important;
}
.margin_top_3_5rem {
    margin-top: 3.5rem;
}
.margin_top_4rem {
    margin-top: 4rem;
}
.margin_top_1_7rem {
    margin-top: 1.7rem;
}
.margin_top_10rem {
    margin-top: 10rem;
}
.margin_top_5px{
    margin-top: 5px !important;
}
.margin_top_10px{
    margin-top: 10px
}
.margin_top_11px {
    margin-top:11px;
}
.margin_top_13px {
    margin-top: 13px;
}
.margin_top_13px_left {
    margin-top: 13px;
    left: 18px;
}
.margin_top_15px{
    margin-top: 15px;
}
.margin_top_17px {
    margin-top: 17px;
}
.margin_top_20px {
    margin-top: 20px;
}
.margin_top_23px {
    margin-top: 23px;
}
.margin_top_25px {
    margin-top: 25px;
}
.margin_top_27px {
    margin-top: 27px
}
.margin_top_35px {
    margin-top: 35px
}
.margin_top_10p {
    margin-top: 10%;
}
.margin_top_n0_5rem {
    margin-top: -0.5rem;
}
.margin_top_n2px {
    margin-top: -2px;
}
.margin_top_n5px {
    margin-top: -5px;
}
.margin_top_n6px {
    margin-top: -6px;
}
.margin_top_n1rem {
    margin-top:-1rem
}
.margin_top_n1_5rem {
    margin-top:-1.5rem
}
.margin_top_n2rem {
    margin-top:-2rem
}
.margin_top_n2rem {
    margin-top:-2rem
}
.margin_top_n2_5rem {
    margin-top:-2.5rem
}
.margin_top_n3_5rem {
    margin-top:-3.5rem;
}
.margin_top_n5rem {
    margin-top: -5rem;
}
.margin_top_n10_5rem {
    margin-top:-10.5rem
}
.margin_top_n10_5rem {
    margin-top:-10.5rem;
}
.margin_top_n12px {
    margin-top: -12px !important
}
.margin_top_n13px {
    margin-top: -13px;
}
.margin_top_n17px {
    margin-top: -17px;
}


//margin-left
.margin_left_auto {
    margin-left: auto;
}
.margin_left_n1rem{
    margin-left: -1rem !important;
}
.margin_left_n2rem{
    margin-left: -2rem !important;
}
.margin_left_n3rem{
    margin-left: -3rem;
}
.margin_left_n7rem{
    margin-left: -7rem;
}
.margin_left_n27-rem {
    margin-left: -27rem !important;
}
.margin_left_0rem {
    margin-left:0rem !important  
}
.margin_left_0_5rem {
    margin-left:0.5rem;
}
.margin_left_0_4rem {
    margin-left: 0.4rem !important;
}
.margin_left_0_8rem {
    margin-left: 0.8rem
}
.margin_left_15rem {
    margin-left:15rem !important;
}
.margin_left_16_5rem {
    margin-left:16.5rem;
}
.margin_left_19_5rem {
    margin-left:19.5rem;
}
.margin_left_20rem {
    margin-left:20rem;
}
.margin_left_0_5rem {
    margin-left:0.5rem !important;
}
.margin_left_0_2rem {
    margin-left:0.2rem !important;
}
.margin_left_1_1rem {
    margin-left:1.1rem !important;
}
.margin_left_2rem {
    margin-left: 2rem;
}
.margin_left_3px{
    margin-left: 3px;
}
.margin_left_1rem {
    margin-left: 1rem;
}
.margin_left_3rem {
    margin-left: 3rem;
}
.margin_left_3_5rem {
    margin-left: 3.5rem;
}
.margin_left_4rem {
    margin-left: 4rem;
}
.margin_left_5_5rem {
    margin-left: 5.5rem;
}
.margin_left_7_5rem {
    margin-left: 7.5rem;
}
.margin_left_8rem {
    margin-left: 8rem;
}
.margin_left_9rem {
    margin-left: 9rem;
}
.margin_left_10px {
    margin-left: 10px
}
.margin_left_12px {
    margin-left:12px;
}
.margin_left_14px {
    margin-left:14px;
}
.margin_left_20px {
    margin-left: 20px
}
.margin_left_28px {
    margin-left: 28px
}

.margin_left_30px{
    margin-left: 30px
}
.margin_left_100px{
    margin-left: 100px;
}
.margin_left_n11px {
    margin-left: -11px;
}
.margin_left_n12px {
    margin-left:-12px;
}
.margin_left_2_2{
    margin-left: 2.2rem !important;
}
.margin_left_37px{
    margin-left: 37px !important;
}

//margin-right
.margin_right {
    margin-right:0px !important;
}
.margin_right_0_2rem {
    margin-right:0.2rem !important;
}
.margin_right_1rem {
    margin-right: 1rem
}
.margin_left_2rem {
    margin-left: 2rem
}
.margin_top_0_8rem {
    margin-top: 0.8rem
}
.margin_right_0_7rem {
    margin-right: 0.7rem
}
.margin_left_32rem {
    margin-left: 32rem
}
.margin_right_1_2rem {
    margin-right: 1.2rem;
}
.margin_right_1_3rem {
    margin-right: 1.3rem;
}
.margin_right_1_6rem {
    margin-right: 1.6rem !important;
}
.margin_right_2_5rem {
    margin-right: 2.5rem;
}
.margin_right_3rem {
    margin-right: 3rem;
}
.margin_right_3_5rem {
    margin-right: 3.5rem;
}
.margin_right_0_5rem {
    margin-right: 0.5rem;
}
.margin_right_n4rem {
    margin-right: -4rem;
}
.margin_right_n2_3rem {
    margin-right: -2.3rem;
}
.margin_right_n1_9rem {
    margin-right: -1.9rem;
}
// padding-top property

.padding_top_0_2rem {
    padding-top: 0.2rem
}
.padding_top_0_3rem {
    padding-top: 0.3rem
}
.padding_top_0_4rem {
    padding-top: 0.4rem;
}
.padding_top_0_5rem {
    padding-top: 0.5rem;
}
.padding_top_0_7rem {
    padding-top: 0.7rem
}
.padding_top_0_8rem {
    padding-top: 0.8rem
}
.padding_top_1rem {
    padding-top: 1rem;
}
.padding_top_1_5rem {
    padding-top: 1.5rem
}
.padding_top_2rem {
    padding-top: 2rem
}
.padding_top_2_5rem{
    padding-top: 2.5rem;
}
.padding_top_4_5rem{
    padding-top: 4.5rem;
}
.padding_top_1_5rem{
    padding-top: 1.5rem;
}
.padding_top_1_8rem {
    padding-top: 1.8rem
}
.padding_top_7rem {
    padding-top: 7rem;
}
.padding_top_5px {
    padding-top: 5px;
}
.padding_top_6px {
    padding-top: 6px;
}
.padding_top_4px {
    padding-top:4px
}
.padding_top_7px{
    padding-top: 7px;
}
.padding_top_10px {
    padding-top: 10px
}
.padding__top_12px {
    padding-top: 12px;
}
.padding__top_13px {
    padding-top: 13px;
}
.padding__top_14px {
    padding-top: 14px !important;
}
.padding_top_15px {
    padding-top: 15px;
}
.padding_top_16px {
    padding-top: 16px;
}
.padding_top_20px {
    padding-top: 20px;
}
.padding_top_23px {
    padding-top: 23px
}
.padding_top_25px{
    padding-top: 25px;
}
.padding_top_27px {
    padding-top: 27px;
}
.padding_top_30px {
    padding-top: 30px;
}
//padding-bottom

.padding_bottom_1px {
    padding-bottom: 1px
}
.padding_bottom_3px{
    padding-bottom: 3px
}
.padding_bottom_5px{
    padding-bottom: 5px
}
.padding_bottom_6px{
    padding-bottom: 6px
}
.padding_bottom_7px{
    padding-bottom: 7px
}
.padding_bottom_8px{
    padding-bottom: 8px
}
.padding_bottom_9px{
    padding-bottom: 9px
}
.padding_bottom_10px{
    padding-bottom: 10px
}
.padding_bottom_25px{
    padding-bottom: 25px
}
.padding_bottom_0_5rem {
    padding-bottom: 0.5rem
}
.padding_bottom_7rem {
    padding-bottom: 7rem
}

//padding-left

.padding_left_0_3rem {
    padding-left: 0.3rem
}
.padding_left_0_5rem {
    padding-left: 0.5rem  !important;
}
.padding_left_0_7rem {
    padding-left: 0.7rem;
}
.padding_left_0_8rem {
    padding-left: 0.8rem !important;
}
.padding_left_1rem{
    padding-left: 1rem !important;
}
.padding_left_1_2rem{
    padding-left: 1.2rem;
}
.padding_left_1_5rem{
    padding-left: 1.5rem !important;
}
.padding_left_2rem{
    padding-left: 2rem !important;
}
.padding_left_3rem{
    padding-left: 3rem !important;
}
.padding_left_5rem {
    padding-left: 5rem;
}
.padding_left_5_3rem {
    padding-left: 5.3rem;
}
.padding_left_7rem {
    padding-left: 7rem !important;
}
.padding_left_8rem {
    padding-left: 8rem !important;
}
.padding_left_9rem {
    padding-left: 9rem;
}
.padding_left_12rem {
    padding-left: 12rem;
}
.padding_left_14rem {
    padding-left: 14rem;
}
.padding_left_14_5rem {
    padding-left: 14.5rem;
}
.padding_left_15rem {
    padding-left: 15rem;
}
.padding_left_4_5px {
    padding-left: 4.5px !important;
}
.padding_left_5px {
    padding-left: 5px !important
}
.padding_left_10px {
    padding-left: 10px !important
}
.padding_left_14px{
    padding-left: 14px;
}
.padding_left_17px{
    padding-left: 17px;
}
.padding_left_20rem {
    padding-left: 20rem;
}
.padding_left_23px {
    padding-left: 23px;
}
.padding_left_25px {
    padding-left:25px;
}
.padding_left_29px {
    padding-left:29px;
}
.padding_left_30px {
    padding-left:30px;
}
.padding_left_35px {
    padding-left:35px;
}
.padding_left_n5px {
    padding-left: -5px
}
.padding_left_4rem {
    padding-left: 4rem;
}


//padding-right
.padding_right_0_2rem {
    padding-right: 0.2rem;
}
.padding_right_0_3rem {
    padding-right: 0.3rem;
}
.padding_right_0_4rem {
    padding-right: 0.4rem
}
.padding_right_0_5rem {
    padding-right: 0.5rem
}
.padding_right_1rem{
    padding-right: 1rem !important;
}
.padding_right_1_4rem{
    padding-right: 1.4rem !important;
}
.padding_right_2rem{
    padding-right: 2rem !important;
}
.padding_right_3rem{
    padding-right: 3rem;
}
.padding_right_4rem{
    padding-right: 4rem;
}
.padding_right_5rem{
    padding-right: 5rem;
}
.padding_right_6rem {
    padding-right: 6rem;
}
.padding_right_77rem {
    padding-right: 77rem
}
.padding_right_20px{
    padding-right: 20px
}
//min-height
.min-height_0rem {
    min-height:0rem
}
.min-height_2rem {
    min-height:2rem
}
.min-height_4rem {
    min-height: 4rem !important
}
.min_height_20px{
    min-height: 20px !important;
}
.min_height_50px{
    min-height: 50px;
}
.min_height_130px{
    min-height: 130px;
}
.min-height_30_2rem {
    min-height: 30.2rem;
}
.min-height_21_5rem {
    min-height: 21.5rem
}
.min-height_29rem{
    min-height: 29rem
}
.min-height_35rem {
    min-height:35rem
}
.min-height_450px {
    min-height: 450px;
}
.min-height_4vh {
    min-height: 5vh !important;
}

//max-height
.max-height_27px {
    max-height:27px;
}
.max-height_40px {
    max-height:40px;
}
.max-height_50px {
    max-height:50px;
}
.max-height_6rem {
    max-height:6rem;
}
.max-height_7rem {
    max-height: 7rem
}
.max-height_8rem {
    max-height: 8rem !important;
}
.max-height_9rem {
    max-height: 9rem
}
.max-height_10rem {
    max-height: 10rem;
}
.max-height_11rem{
    max-height: 11rem;
}
.max-height_12rem{
    max-height: 12rem !important
}
.max-height_14rem {
    max-height:14rem;
}
.max-height_15rem {
    max-height: 15rem;
}
.max-height_16rem{
    max-height: 16rem;
}
.max-height_18_2rem {
    max-height: 18.2rem
}
.max-height_20rem {
    max-height: 20rem
}
.max-height_21rem {
    max-height: 21rem !important
}
.max-height_22rem {
    max-height: 22rem
}
.max-height_22_59rem {
    max-height: 22.59rem
}
.max-height_23_8_rem{
    max-height: 23.8rem;
}
.max-height_25rem{
    max-height:25rem;
}
.max-height_30rem {
    max-height: 30rem
}
.max-height_32rem {
    max-height: 32rem
}
.max-height_35rem {
    max-height: 35rem;
}
.max-height_45rem {
    max-height: 45rem;
}
.max-height_49rem {
    max-height:49rem
}
.max_height_55rem{
    max-height: 55rem
}
.max_height_200px {
    max-height: 200px;
}
.max_height_214px{
    height: 214px !important;
}
.max_height_300px {
    max-height: 300px
}
.max-height_700px{
    max-height: 700px
}
.max-height_40vh {
    max-height: 40vh
}
.max-height_80vh{
    max-height: 80vh
}
.max_height_70vh {
    max-height : 70vh
}
.max-height_26vh{
    max-height: 26vh
}

//max-width

.max-width_15px {
    max-width: 15% !important;
}
.max-width_18px {
    max-width: 18% !important;
}
.max-width_292px {
    max-width: 292px;
}
.max-width_35rem{
    max-width: 35rem;
}
.max-width_35_5rem{
    max-width: 35.5rem !important;
}
.max-width_13_3pr {
    max-width: 13.3% !important;
}
.max-width_90_pr {
    max-width: 90% !important;
}
.max-width_850px {
    max-width: 850px;
}
//min-width

.min_width_100px{
    min-width: 100px
}
.min_width_10rem{
    min-width: 10rem !important;
}
.min_width_13rem{
    min-width: 13rem !important;
}
// color property

.color_green {
    color: $green !important;
}
.color_dark_green {
    color: $dark-green;
}
.color_red {
    color:$red !important;
}
.color_white {
    color: $white !important;
}
.color_cyan {
    color: $cyan !important;
}
.color_orange {
    color: $orange;
}
.color_off_white {
    color: $off-white;
}
.color_dark_blue{
    color: $dark_blue
}

.color_495057 {
    color: #495057 !important;
}
.color_dimgray {
    color: dimgray;
}
.color_inActive {
    color: #cccccc !important;
}
.color_grey {
    color: $grey !important;
}
.color_dark_grey {
    color: $dark-gray !important;
}
.color_black {
    color:$black !important
}
.color_skyblue{
    color: $sky-blue !important
}
.color_lightSkyBlue {
   color: $lightSkyBlue !important;
}
.color_thick_light_gray{
    color : $thick_light_gray !important;
}
.text_color_black{
    color : $text-color-black !important;
}
.color_lightwhite{
    color : $lightWhite !important;
}
.color_checkboxblue {
    color: #0075FF !important;
}
//height  property

.height_1px{
    height: 1px;
}
.height_10px{
    height: 10px;
}
.height_23px {
    height: 23px;
}
.height_18px {
    height: 18px;
}
.height_20px {
    height: 20px;
}
.height_24px {
    height: 24px;
}
.height_36px {
    height: 36px !important
}
.height_50px {
    height: 50px
}
.height_100px {
    height: 100px;
}
.height_76px {
    height: 76px;
}
.height_140px {
    height: 140px;
}
.height_320px {
    height: 320px !important;
}
.height_745px {
    height: 745px
}
.height_0_5rem{
    height: 0.5rem;
}
.height_1rem {
    height:1rem;
}
.height_1_2rem {
    height:1.2rem;
}
.height_1_5rem{
    height: 1.5rem;
}
.height_1_8rem {
    height: 1.8rem !important;
}
.height_2rem {
    height: 2rem !important;
}
.height_2_2rem {
    height: 2.2rem !important;
}
.height_2_3rem {
    height: 2.3rem
}
.height_2_5rem {
    height: 2.5rem !important
}
.height_2_6rem{
    height: 2.6rem;
}
.height_2_8rem{
    height: 2.8rem !important;
}
.height_2_9rem{
    height: 2.9rem !important;
}
.height_2_7rem{
    height: 2.7rem !important;
}
.height_3rem {
    height: 3rem;
}
.height_3_2rem {
    height:3.2rem
}
.height_3_2--5rem{
    height: 3.25rem !important;
}
.height_3_5rem{
    height: 3.5rem;
}
.height_4rem {
    height: 4rem;
}
.height_4_5rem {
    height: 4.5rem;
}
.height_4_6rem {
    height: 4.6rem;
}
.height_4_8rem {
    height: 4.8rem;
}
.height_5rem {
    height: 5rem;
}
.height_5_6rem {
    height: 5.6rem !important;
}
.height_6rem {
    height: 6rem !important;
}
.height_6_1rem {
    height: 6.1rem !important
}
.height_8_8rem {
    height: 8.5rem !important
}
.height_9rem {
    height:9rem !important;
}
.height_10rem {
    height: 10rem !important;
}
.height_10_5rem {
    height: 10.5rem !important;
}
.height_11rem {
    height: 11rem !important;
}
.height_11_2rem {
    height: 11.2rem
}
.height_12_2rem {
    height: 12.2rem
}
.height_13rem {
    height: 13rem
}
.height_13_5rem {
    height: 13.5rem
}
.height_14_5rem {
    height:14.5rem;
}
.height_15rem {
    height: 15rem !important;
}
.height_15_5rem {
    height: 15.5rem !important;
}
.height_16rem {
    height:16rem !important;
}
.height_16_5rem {
    height: 16.5rem !important;
}
.height_19vh{
    height: 19vh;
}
.height_17rem {
    height: 17rem
}
.height_18rem {
    height: 18rem;
}
.height_19rem {
    height: 19rem !important;
}
.height_20rem {
    height: 20rem !important;
}
.height_22rem {
    height: 22rem
}
.height_24rem {
    height: 24rem !important;
}
.height_25rem {
    height: 25rem !important;
}
.height_25_5rem{
    height: 25.5rem;
}
.height_26rem {
    height: 26rem;
}
.height_27rem {
    height: 27rem !important
}
.height_28rem {
    height: 28rem !important
}
.height_30rem {
    height: 30rem
}
.height_31rem {
    height: 31rem
}
.height_32_2rem {
    height: 32.2rem;
}
.height_37_rem{
    height: 37rem !important
}
.height_38rem {
    height: 38rem;
}
.height_40vh{
    height: 40vh;
}
.height_40rem {
    height: 40rem
}
.height_41rem {
    height: 41rem
}
.height_42rem {
    height:42rem !important;
}
.height_46rem {
    height:46rem
}
.height_47rem {
    height:47rem !important
}
.height_48rem {
    height: 48rem
}
.height_50rem {
    height: 50rem;
}
.height_51rem {
    height: 51rem;
}
.height_54rem {
    height: 54rem !important;
}
.height_55rem {
    height: 55rem;
}
.height_56rem {
    height: 56rem !important;
}
.height_58rem {
    height: 58rem
}
.height_auto {
    height: auto !important
}
.height_25vh {
    height: 25vh
}
.height_40vh{
    height: 40vh;
}
.height_75_14vh {
    height : 75.14vh !important
}
.height_82vh {
    height: 82vh
}
.height_174px{
    height: 174px
}
.height_110px{
    height: 110px
}

// margin-left
.margin_left_n1px {
    margin-left:-1px;
}
.margin_left_n8px {
    margin-left:-8px;
}
.margin_left_17_5rem {
    margin-left:17.5rem;
}
.margin_left_7rem {
    margin-left:7rem
}
.margin_left_16rem {
    margin-left:16rem;
}
.margin_left_n6rem {
    margin-left:-6rem
}
.margin_left_n8rem {
    margin-left:-8rem
}


//margin-bottom
.margin_bottom_0px {
    margin-bottom: 0px;
}
.margin_bottom_0_5rem {
    margin-bottom: 0.5rem
}
.margin-bottom-0_714rem{
    margin-bottom: 0.714rem
}
.margin_bottom_1rem {
    margin-bottom: 1rem
}
.margin_bottom_2rem {
    margin-bottom: 2rem;
}
.margin_bottom_1_75rem {
    margin-bottom: 1.75rem
}
.margin_bottom_5px {
    margin-bottom: 5px !important;
}
.margin_bottom_10px {
    margin-bottom: 10px
}
.margin_bottom_n1rem {
    margin-bottom: -1rem
}
.margin_bottom_n1_5rem {
    margin-bottom: -1.5rem !important;
}
.margin_bottom_n2rem {
    margin-bottom: -2rem;
}

.margin_bottom_n2_3rem {
    margin-bottom: -2.3rem;
}

//margin
.margin_1_0_0_1rem{
    margin: 1rem 0rem 0rem 1rem;
}
.margin_2_0_33_75_0rem {
    margin: 2rem 0rem 33.75rem 0rem
}
.margin_5_0_0_0px{
    margin: 5px 0px 0px 0px;
}
.margin_0_5_0_7_0_0_7rem{
    margin: 0.5rem 0.7rem 0rem 0.7rem;
}
.margin_0_7rem{
    margin:0.7rem;
}
.margin_2_30rem {
    margin:-2rem 0rem 0rem -30rem
}
.margin_1rem_0_5rem{
    margin: 1rem 0.5rem;
}
.margin_0_5rem_0rem{
    margin: 0.5rem 0rem;
}
.margin_0_5rem_0_0_0rem{
    margin: 0.5rem 0rem 0rem 0rem;
}
.margin_0_5rem_0_0_0_7rem{
    margin: 0.5rem 0rem 0rem 0.7rem;
}
.margin_1px {
    margin: 1px !important;
}

//bottom

.bottom_0_3rem {
    bottom: 0.3rem
}
.bottom_0_5rem {
    bottom: 0.5rem
}
.bottom_n2px {
    bottom:-2px;
}
.bottom_8px {
    bottom: 8px;
}
//left
.left_0_37rem {
    left: 0.37rem
}
.left_0_7rem {
    left: 0.7rem
}
.left_0_9rem {
    left: 0.9rem
}
.left_n0_5rem {
    left: -0.5rem;
}
.left_n1rem {
    left: -1rem !important;
}
.left_n1_1rem {
    left: -1.1rem !important;
}
.left_n1_5rem {
    left: -1.5rem !important;
}
.left_n5_1rem {
    left: -5.1rem !important;
}
.left_0_5rem {
    left:0.5rem !important
}
.left_1rem {
    left:1rem
}
.left_1_1rem {
    left:1.1rem
}
.left_1_2rem{
    left:1.2rem;
}
.left_1_5rem{
    left:1.5rem !important;
}
.left_1_7rem{
    left:1.7rem;
}
.left_1_8rem{
    left:1.8rem;
}
.left_2rem{
    left:2rem !important;
}
.left_3rem{
    left:3rem;
}
.left_4rem{
    left:4rem !important;
}
.left_4_2rem{
    left:4.2rem !important;
}
.left_4_5rem{
    left:4.5rem !important;
}
.left_5rem{
    left:5rem !important;
}
.left_6rem{
    left:6rem !important;
}
.left_6_5rem{
    left:6.5rem !important;
}
.left_6_8rem{
    left:6.8rem !important;
}
.left_7_1rem{
    left:7.1rem !important;
}
.left_8rem {
    left: 8rem;
}
.left_9rem {
    left: 9rem;
}
.left_10rem {
    left: 10rem !important;
}

.left_10_5rem {
    left: 10.5rem;
}
.left_11rem{
    left: 11rem !important;
}
.left_11_5rem{
    left: 11.5rem;
}
.left_12rem{
    left: 12rem;
}
.left_13rem {
    left: 13rem !important;
}
.left_14rem{
    left: 14rem;
}
.left_14_5rem{
    left: 14.5rem;
}
.left_14_2rem{
    left: 14.2rem;
}
.left_14_3rem{
    left: 14.3rem;
}
.left_15rem {
    left: 15rem !important;
}
.left_15_5rem {
    left: 15.5rem !important;
}
.left_15_7rem {
    left: 15.7rem !important;
}
.left_16rem {
    left: 16rem !important;
}
.left_17rem{
    left: 17rem;
}
.left_21rem{
    left: 21rem;
}
.left_21_2rem{
    left: 21.2rem !important;
}
.left_24rem{
    left: 24rem !important;
}
.left_35rem{
    left: 35rem;
}
.left_n2_6rem {
    left:-2.1rem;
}
.left_n16px {
    left:-16px
}
.left_6px{
    left: 6px;
}
.left_8px{
    left: 8px;
}
.left_8rem{
    left: 8rem;
}
.left_ngtv_9999_px{
    left: -9999px
}
.left_83per{
    left: 83%;
}

.smart-filter {
    height: 2.57rem;
    &:hover,&:active,&:focus {
        border: 1px solid $light-blue;
        background-color: $input-focus !important ;
    }
    .wj-input-group-btn:last-child > .wj-btn {
        border-left-width: 0px;
    }
    .wj-btn-default:hover {
        background-color: rgba(183, 175, 175, 0.1);
    }
}
//width
.width_unset{
    width: unset !important;
}
.width_1_2rem {
    width:1.2rem;
}
.width_1_3rem {
    width:1.3rem;
}
.width_1_35rem {
    width:1.35rem;
}
.width_1_6rem {
    width:1.6rem;
}
.width_1_7rem {
    width:1.7rem;
}
.width_2rem {
    width:2rem;
}
.width_2_1rem {
    width:2.1rem;
}
.width_3rem {
    width: 3rem
}
.width_3_2rem{
    width:3.2rem
}
.width_4rem{
    width: 4rem;
}
.width_5rem{
    width: 4rem;
}
.width_201px {
    width: 201px !important;
}
.width_6rem {
    width: 6rem;
}
.width_7rem {
    width: 7rem;
}
.width_8rem{
    width: 8rem;
}
.width_9rem{
    width: 9rem;
}
.width_9per{
    width: 9%;
}
.width_10rem {
    width: 10rem;
}
.width_12rem{
width:12rem;
}
.width_13rem{
    width:13rem !important;
    }
.width_14rem {
    width: 14rem;
}
.width_16_9rem {
    width: 16.9rem !important;
}
.width_17_3rem {
    width: 17.3rem !important;
}
.width_21rem {
    width: 21rem;
}
.width_24rem {
    width: 24rem;
}
.width_23px {
    width: 17px;
}
.width_13px {
    width: 13px !important;
}
.width_18px {
    width: 18px !important;
}
.width_19px {
    width: 19px !important;
}
.width_23px {
    width: 23px;
}
.width_24px {
    width: 24px !important;
}
.width_29px {
    width: 29px !important;
}
.width_39rem {
    width: 39rem !important;
}
.width_40px {
    width: 40px;
}
.width_42px {
    width: 42px !important;
}
.width_50px {
    width: 50px !important;
}
.width_60rem {
    width: 60rem !important;
}
.width_80px {
    width: 80px;
}
.width_85px {
    width: 85px !important;
}
.width_93px {
    width: 93px !important;
}
.width_100px {
    width: 100px !important;
}
.width_140px {
    width: 140px;
}
.width_150px {
    width: 150px !important;
}
.width_180px {
    width: 180px;
}
.width_95per{
    width:95%
}
.width_40per{
    width:40%
}
.width_100per{
    width:100%
}
.width_300px {
    width: 300px;
}
.width_90Per{
    width:90%
}
.width_87Per{
    width:87%
}
.width_97Per{
    width: 97%
}
.height_84_5Per{
    height: 84.5%
}
.height_99_5Per{
    height: 99.5%
}
.text_transform {
    text-transform: none;
}
.line_height_0_1rem {
    line-height: 0.1rem;
}
.line_height_0_9 {
    line-height: 0.9
}
.line_height_1{
    line-height: 1
}
.line_height_1_5 {
    line-height: 1.5
}
.line_height_1_6 {
    line-height: 1.6;
}
.line_height_1_2 {
    line-height: 1.2;
}
.line_height_1_9 {
    line-height: 1.9;
}
.line_height_1_5rem{
    line-height: 1.5rem !important;
}
.line_height_2rem{
    line-height: 2rem !important;
}
.line_height_1rem{
    line-height: 1rem !important;
}
.line_height_1_8rem{
    line-height: 1.8rem !important;
}
.line_height_2-3rem{
    line-height: 2.3rem !important;
}
.line_height_2-4rem{
    line-height: 2.4rem !important;
}
.line_height_normal {
    line-height: normal !important;
}

//border
.border_1px_solid{
    border: 1px solid
}
.border_1px_solid_black{
    border: 1px solid black;
}
.border_3px_solid_black {
    border: 3px solid $black ;
}
.border_1px_solid_gray {
    border: 1px solid $grey
}

//border_bottom
.border_bottom_1_5_px_blue{
    border-bottom: 1.5px #5fb0d5 solid;
}
.border_bottom_0_21_rem_blue{
    border-bottom: 0.21rem #5fb0d5 solid;
}
.border_bottom_3px_solid{
    border-bottom: 3px solid
}

//border_right
.border_right_1px_solid_white{
    border-right: 1px solid #ddd;
}

//border_color
.border_color_gray{
    border-color: gray
}
.border_color_blue {
    border-color: #31afe5 !important;
}

//display
.display_inherit{
    display: inherit;
}
.display_initial{
    display: initial;
}

//object-fit
.object_fit_contain{
    object-fit: contain
}


//word break

.word_break {
    word-break: break-word;
}

.word_break_all {
    word-break: break-all
}

.border_radius_4px {
    border-radius: 4px
}
.border_radius_5px {
    border-radius: 5px !important;
}
.border_radius_6px {
    border-radius: 6px
}
.border_radius_50per {
    border-radius: 50%
}
//right
.right_n30px{
    right: -30px
}
.right_3px {
    right: 3px;
}
.right_0_2rem {
    right: 0.2rem
}
.right_0_67rem {
    right: 0.67rem
}
.right_1rem{
    right: 1rem
}
.right_1_2_rem{
    right: 1.2rem
}
.right_2rem{
    right: 2rem
}
.right_3rem {
    right: 3rem
}
.right_1_5_rem{
    right: 1.5rem
}
.right_4rem {
    right: 4rem
}
.right_7rem {
    right: 7rem
}
.right_7_5rem {
    right: 7.5rem
}
.right_5rem {
    right: 5rem
}
.right_10rem {
    right: 10rem
}
.right_40rem {
    right: 40rem
}
.right_45rem {
    right: 45rem;
}
.right_45_5rem {
    right: 45.5rem;
}
.right_28px {
    right: 28px
}
.right_n0_5rem {
    right: -0.5rem;
}
.right_n0_7rem {
    right: -0.7rem;
}
.right_n1_8rem {
    right: -1.8rem;
}
.right_n2rem {
    right: -2rem;
}
.right_n2_5rem {
    right: -2.5rem;
}
.right_n1rem {
    right: -1rem;
}
.right_n3rem {
    right: -3rem;
}
.right_n4rem {
    right: -4rem;
}
.right_n4_8rem {
    right: -4.8rem;
}
.right_n7rem {
    right: -7rem;
}
.right_n10rem {
    right: -10rem;
}
.right_10px {
    right: 10px;
}

//list style

.list_style_none {
    list-style: none
}

// cursor

.cursor_notAllowed {
    cursor: not-allowed
}
.cursor_pointer {
    cursor: pointer !important;
}
.cursor_noDropImp {
    cursor: no-drop !important;
}

.display_inline-flex{
    display: inline-flex;
}
// white space
.white_space_unset{
    white-space:  unset;
}
.border_top--none{
    border-top: none;
}

//font-family
.font_style{
    font-family: "Roboto" !important;
}


.noteIcon_size {
    font-size: 23px;
    margin-left: 10px;
}

.inactive_row {
    background-color: #e9e3e3;
    cursor: not-allowed !important;
    &:hover {
        background-color: #e9e3e3 !important;
    }

}
.warning-popup {
    border-radius: 50%;
    width: 23px;
    color: #FFFFFF;
    background: #F6251A;
    font-size: 1rem !important;
    font-weight: bolder !important;
    text-align: center !important;
    cursor: pointer;
}
.disabled-popup {
    border-radius: 50%;
    width: 23px;
    color: #FFFFFF;
    background: #888888;
    font-size: 1rem !important;
    font-weight: bolder !important;
    text-align: center !important;
    cursor: pointer;
}
.success-popup{
    border-radius: 50%;
    width: 23px;
    color: #FFFFFF;
    background: #51a351;;
    font-size: 1rem !important;
    font-weight: bolder !important;
    text-align: center !important;
    cursor: pointer;
}
.min_width_5_5rem {
    min-width: 5.5rem !important;
}

.container_clinical{
    //height: 47rem;
   // display: flex;
    background-color: #ecf4fa;
    flex-direction: column;
    justify-content: space-between;
    //overflow-y: auto !important;
    //overflow-x: hidden;
    //position: relative;
    max-height: 500px;
    overflow:auto
  }

  .tbstud {
    width: 100%;
    /* margin: 50px auto; */
    border-collapse: separate;
    border-spacing: 4px;
    padding-top: 1rem;
    /* box-shadow: 0 1px 4px 0px rgb(0 0 50 / 15%); */
    border-radius: 5px;
}

.customer_row td {
    vertical-align: middle;
    font-family: "Roboto";
    height: 36px;
    padding-left: 15px;
    font-size: 13px;
    font-weight: 500;
    //color: #5d636b;
    background-color: white;
    box-shadow: 0 0px 3px 0px rgb(0 0 0 / 15%);
    cursor: pointer;
  }

  .dispill-table {
    .bg-grey {
        background-color: #F3FBFE;
        color:black;
        font-weight: 400;
        height: 2rem !important;
        position: sticky;
        top: 42px;
        z-index: 2;
    }
    .tableFixHead {
      overflow: auto;
      height: 400px;
      border-radius: 10px;
    }
    .file_generator_prev {
        overflow-y: auto;
        height: 283px !important;
        border-radius: 10px;
      }
    .specificTym {
        th:last-child{
            border-top-right-radius: 10px;
        }
    }
    table {
        border-collapse: collapse;
        width: 100%;
        td:nth-child(1) {
            background-color: #F5F7F9;
        }
        tr:last-child td:last-child{
            border-top-right-radius: 10px;
        }
    }
    .day-fraction-header {
        th:nth-child(1) {
            background-color: #F5F7F9;
            top: 42px;
        }
    }
    th, td {
        border: 1px solid #E9EAEB;
        padding: 2px 11px;
        padding-top:0px;
        flex-shrink: 0;
    }
    .bold-text {
        font-weight: bold;
    }
    th {
        background-color: #31AFE5;
        height: 3rem;
        color: white;
        padding: 2px 4px;
        position: sticky;
        top: 0px;
        z-index: 1;
    }
    .custom--input {
        height: 25px;
    }
    .ng-select .ng-select-container {
        border: none;
    }
    .form-control {
        border: none !important;
    }
    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
        padding-right: 2px !important;
    }
  }
  .dispill-box {
    label {
        //color:black !important;
        font-weight: 500 !important;
    }
    .format {
        color: #31AFE5;
    }
    .custom--input {
        height: none;
    }
    border:1px solid #CCD6DE;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .batch-edit {
    table{
        width: 100%;
        text-align: center;
        border-collapse: collapse;
    } th, td {
        border: 1px solid #E9EAEB;
        cursor: pointer;
    }
    th{
        background-color: #31AFE5;
        color: white;
    }
  }
  .batch-edit_max_height{
    max-height: 500px;
    overflow: auto;
  }
 
.left_warnType_red {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-left: 10px solid  #F6251A;
    color:  black;
    width: 200px;
}
.left_warntype_mod {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-left: 10px solid #FFD951;
    color: black;
}

.left_warntype_sev {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-left: 10px solid #f7941d;
    color: black;
}

  .right{
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }



  .box_header_curve_edges{
    border-top-right-radius: 0.6rem;
    border-top-left-radius: 0.6rem;
    height: 48px !important;
  }
  .box_headerName{
    font-size: 1.3rem;
    font-family: "Roboto";
    font-weight: 400 !important;
  }

  .fields_inside_box{
    padding-bottom: 0.5rem;
    padding-left: 0rem;
  }
  .input_height{
    height: 5rem !important;
  }
  .form-control-settings {
  .form-control {
    height: 36px !important;
    border: 1px solid #99AEBE!important;
    border-radius: 5px !important;
    color: #5d636b;
    font-weight: 400;
    border-color: #5d636b;
}
}
.label_color{
   color: #31AFE5 !important;
   font-weight: 400;
   font-size: 1.1rem;
}
.box_curve_edges{
    border-top-right-radius: 0.6rem !important;
    border-top-left-radius: 0.6rem !important;
    border-bottom-left-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important;
}
.settings--heading--title{
    color: #5d636b;
    font-family: "Roboto" !important
}
.dropdown-Rx-generation {
    .ng-select-container {
        bottom: -4px !important;
    }
}

.stateCityZip{
    .displayStateCityZip{
            line-height: 2;
            .label{
                color: #31AFE5 !important;
                font-weight: 400;
                font-size: 1.1rem;
            }


    }

}
.accountsettings--hideditblock{
    color: #fff;
    font-size: 1.6rem;
}



.fax_disclaimer{
    align-items: center;
    height: 29px;
    width: 293px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #5D636B;
    top: 11px;
    position: relative;
    &:hover,&:active,&:focus {
        clip-path: polygon(0% 0%, calc(100% - 1.4em) 0%, 100% 50%, calc(100% - 1.4em) 100%, 0% 100%) !important;
        display: flex;
        background: $primary !important;
        height: 29px;
        top: 6px;
        color: white
    }
}
.fax_report_arrow
   > option:checked {
        clip-path: polygon(0% 0%, calc(100% - 1.4em) 0%, 100% 50%, calc(100% - 1.4em) 100%, 0% 100%);
        display: flex;
        background: #ffffff;
        height: 29px;
        top: 6px;
    }

.fax_span_label_color{
    .custom-control-label{
        color: #31AFE5 !important;
        font-weight: 400;
        font-size: 1.1rem;
    }
}

.dropdown-default-msg-type {
    .ng-select-container {
        bottom: -21px !important;
    }
}
.max-width-dropdowns {
    .ng-select {
        width: 18rem !important;
    }
}
.erx-inputs {
    .custom--input {
        width: 8rem !important;
    }
}
.dms_arrow
   > option:checked {
        clip-path: polygon(0% 0%, calc(100% - 1.4em) 0%, 100% 50%, calc(100% - 1.4em) 100%, 0% 100%);
        display: flex;
        background: #31AFE5;
        top: 6px;
        color: white;
}
.dms_subCat{
    .fax_disclaimer{
        left: 2rem;
    }
}
.dms_scroll{
    position: relative;
    margin-top: 1.5rem;
}

.dms_scroll_design{
    display: flex;
    color: var(--gray-9);
    width: 25px;
    background-color: #BECBD4;
    flex-direction: column;
    border-radius: 100px;
    align-items: center;
    margin-top: 2rem;
    height: 15rem;
}

.scroll_select{
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    width: var(--size-8);
    aspect-ratio: 1;
}
.pagination__btn_active {
    color: var(--gray-0);
    background-color: rgba(0, 0, 0, 0.5);
}
.scroll_select:hover:not(.pagination__btn_active) {
    background-color: #a3b1bb;
    width: 20px;
    border-radius: 43px;
    text-decoration: none;
}
.view_mode-tex-display{
    color: #5E646C;
    font-weight: 400;
    font-size: 1.1rem;
}

.copay_icon{
    font-size: 1.41rem !important;
    margin-top: 0px !important;
}
//Release Notes styles
.margin-top_releasenotes{
margin-top: 3rem !important;
}
.releasenotes .nav-link2 {
        display: flex;
        font-size: 20px;
        padding: 30px 0 0 30px;
        color: #5D636B !important;
        background-color: white;
        align-items: center;
}
.releasenotes .buildversion{
    margin-left:0rem;
}
.releasenotes h1{
    font-size: 22px;
    margin-left: 1.6rem;
    color: #5D636B;
    margin-top: 15px;
    font-weight: 500;
}
.releasenotes h6 {
      position: relative;
      padding-top: 5px;
      padding-bottom: 5px;
      color: rgb(0, 0, 0) !important;
      font-size: 12px;
      margin-left: 2rem;
      font-weight: 900;
}
.releasenotes h6{
    strong{
        font-weight: bold;
    }
}
.releasenotes h2 {
    font-size: 17px !important;
    color: #0e8abd !important;
    margin-left: 42px;
    font-weight: 600;
    margin-top: 15px;
}
.releasenotes h3{
    font-size: 15px !important;
    color: #2FAFE4 ;
}
.releasenotes h4:not(h6,h2) strong {
      font-size: 15px !important;
      color: black !important;
      background-color: #f0e8fd;
      border-radius: 10px;
      margin-left: 1.5rem;
      padding: 5px 20px 5px 20px;
}
.releasenotes h5:not(h6,h2) strong {
    font-size: 15px !important;
    color: black !important;
    background-color: #FFEAD6;
    border-radius: 10px;
    margin-left: 1.5rem;
    padding: 5px 20px 5px 20px;
}
  .releasenotes h3:not(h5,h4,h6) strong {
    font-size: 15px !important;
    color: black !important;
    background-color: #E1F8F8;
    border-radius: 10px;
    margin-left: 1.5rem;
    padding: 5px 20px 5px 20px;
}
.releasenotes  p:not(li > p){
    font-weight: 400;
    font-size: 15px;
    margin-left: 3rem;
    padding-top: 5px;
    padding-bottom: 5px;
}
.releasenotes li p{
    font-weight: 400;
    font-size: 15px;
}
.releasenotes  ul:not(li > ul){
    margin-left: 2rem;
}
.releasenotes ul {
    font-size: 13px;
    li {
        list-style-type: disc;
        margin-left: 2rem;
        ul {
            li {
                list-style-type: circle;
                ul {
                    li {
                        list-style-type: square;
                    }
                }
            }
        }
    }
    
}
.labeltext{
    color: black;
    font-size: 10px;;
}
.releasenotes input.form-control::placeholder {
    color: gray;
    font-weight: 400;
    font-size: 15px;
    padding-left: 18px;
}
.releasenotes .checkbox-list{
    color: black;
    font-size: 18px;
    padding-left: 18px;
}
.releasenotes .choose-plan{
    border-radius: 10px;
    width: 1265px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 38%);
}
.releasenotes .nav-link1 {
    display: flex;
    height: 50px;
    font-size: 20px;
    padding: 10px 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #2FAFE4!important;
    justify-content: space-between;
    align-items: center;
}
.releasenotes .close {
    float: right;
    font-size: 1rem;
    line-height: 1;
    text-shadow: none;
    opacity: 100%;
}
.releasenotes .header_txt{
    font-family: system-ui;
    font-weight: lighter;
    font-size: 28px;
}
.releasenotes .main_header{
    padding-left: 12rem;
    padding-top: 5rem;
    padding-right: 20rem;
}
.releasenotes .maincontent a{
    font-size: 20px;
}
.releasenotes .maincontent hr{
    font-weight: 900;
    background-color: #000000;
}
.releasenotes h2{
    em{
        background-color: #f7941d;
        border-radius: 10px;
        border: none;
        color: #eeeeee;
        font-size: 22px;
        padding: 5px 15px;
        font-style: normal;
        text-align: center;
    }
}
.releasenotes .has-search .form-control-feedback {
    position: absolute;
      z-index: 2;
      display: block;
      width: 2.375rem;
      height: 2.375rem;
      line-height: 2.4rem;
      text-align: center;
      pointer-events: none;
      font-size: 15px;
      color: #aaa;
      padding-left: 25px;
}
.releasenotes h6.allfeatures{
    font-size: 13px;
    font-weight: 600;
    margin-left: 16px;
    margin-top: 5px;
}
.releasenotes .form-group {
    width: 200px;
    border-radius: 4px;
    align-items: center;
    margin-top: 0.5rem;
    margin-left: 7px;
    margin-bottom: -2px !important;
}
.releasenotes .has-search .form-control {
    padding-left: 2.375rem;
}
.releasenotes input[type=checkbox] {
    width: 15px;
    height: 15px;
}
.releasenotes .form-check-input{
    margin-left: 1rem !important;
}
.releasenotes .scrollbar{
    overflow-x: clip;
    width: 300px;
    height: 750px;
    overflow-y: scroll;
}
.releasenotes ::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    margin-right: 0%;
}
.releasenotes ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #dee1e5;
}
.releasenotes .menu{
    max-height: 747px;
    width: 13vw;
    background: #F7F7F8 !important;
    margin-left: 3px;
}
.releasenotes .maincontent{
    width: 48.9vw;
}
.releasenotes label{
    margin-left: 10px;
    color: black !important;
    font-size: 15px !important;
}
.releasenotes .text-white{
    font-weight: 500;
}
.releasenotes .close-button{
    font-size: 16px !important;
}
.releasenotes .pickbydate{
    margin-left: 19px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    padding-bottom: 5px !important;
    margin-top: 11px;
  }
.releasenotes .noDataclass{
        font-size: 22px;
        margin-left: 21rem;
        color: gray;
        margin-top: 340px;
        font-weight: 500;
}
.releasenotes .scrollwidth{
    padding-right: 0px !important;
    width :102% !important;
    margin-left: 0px;
}
.release_notes_icon{
    font-weight: 500 !important;
    cursor: pointer;
}
.build_count_icon{
    margin-left: 1.2rem;
    margin-top: -1.8rem;
    cursor: pointer;
}
.count_style{
    padding: 8px 6px 7px 5px;
    color: white;
    font-size: 7px;
}
.dot {
    height: 15px;
    width: 15px;
    background-color: #33b0e5;
    border-radius: 50%;
    display: inline-block;
    margin-left: 0px;
}
.version_position{
    margin-left: 4px;
}
.print_icon{
    margin-left: -30px;
}
.dateDropDownClass{
    background-color: white;
    margin-top: -28px;
    margin-left: 13px;
    width: 98%;
    position: relative;
}
.release-months{
color: black !important;
padding: 4px 8px 4px 8px;
margin-left: 10px;
}

.release-border{
    border: solid 0.1px #dad4d4;
    height: 51%;
    width: 10%;
    margin-left:30px;
    background-color: white;
    position: fixed;
    border-radius: 7px;
}
.releasenotes .highlight{
background-color: #c1c4c8;
margin-top: 3px;
border-radius: 7px;
width: 88%;
color: black;
}
.current-releases {
    background-color: #f7941d;
    border-radius: 10px;
    border: none;
    color: #eeeeee;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    margin-left: 30px;
    margin-top: -17px;
  }
  @keyframes glowing {
    0% {
      background-color: #f7941d;
      box-shadow: 0 0 5px #fac078;
    }
    50% {
      background-color: #f7941d;
      box-shadow: 0 0 20px #f9d1a1;
    }
  }
  .current-releases {
    animation: glowing 2000ms infinite;
  }
.checkboxforrelease{
    margin-top: 530px;
}
.releasenotes .ng-arrow-wrapper {
    display: none !important;
}
.releasenotes .unavailable-release{
    margin-top: 365px;
    margin-left: 150px;
}
  .elgliblity_border{
    border-left-style: solid;
    border-left-color: #c6c6c6;
  }
.grey_dashborder {
    color: #9097a0 !important;
}
.orange_dashborder {
    color: #ee9c2e !important;
}
.green_dashborder {
    color: #51a351 !important;
}
.patient_field {
    position: relative;
    top: -4px;
}
.dash_boardInfo{
    width: 1.4rem;
    height: 1.5rem;
}
.global_search{
    position: relative;
    left: -7px !important;
}
.InvRecon_Drug_Report{
    background-color: #f9f7f4;
    border: 1px solid #fdfafa;
    border-radius: 10px;
}
.countNow_Inv_Rec_Report{
    margin-top: 0.3rem;
    background-color: white;
    color: #31afe5 ;
    height: 2rem !important;
}
.countNow_Inv_Rec_Report:hover{
    color: #31afe5 ;
}
.reportWriter {
    height: 90vh !important;
    .close-button{
        font-size: 16px !important;
    }
    .scrollwidth{
        padding-right: 0px !important;
        width :102% !important;
        margin-left: 0px;
    }
    .menu{
        overflow: hidden;
        width: 300px;
        // height: 750px;
        // max-height: 747px;
        background: #ffffff !important;
        border-right-style: inset;
        width:15vw;
        height: 77vh !important;
    }
    .report-border {
        border: solid 0.1px #dad4d4;
        height: 48vh;
        width: 12.8%;
        margin-left:30px;
        background-color: white;
        position: fixed;
        border-radius: 7px;
    }
    .report_treeview {
        padding: 4px;
        border: 0.5px solid #ECECEC;
        border-radius: 10px;
        list-style-type: none !important;
        font-size: unset;
        ::after{
            border-bottom: 2px solid gray !important;
        }
    }
    .report_toggleTree {
        color: $jet ;
        font-size: 1.14rem;
    }
    .nested {
        display: none;
        padding: 0px !important;
        margin-right: -4px !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
        max-height: 30vh !important;
        min-height: 5vh !important;
        li {
            cursor: pointer;
            // list-style-type: none !important;
            margin-right: 0.1rem;
            margin-bottom: -0.8rem;
            padding: 0px;
            list-style-type: none !important;
            font-size: unset !important;
        }
    }
    .active {
        display: block !important;
    }
    .nestedchilds {
        padding: 0px !important;
        margin-right: -4px !important;
        li {
            cursor: pointer;
            list-style-type: none !important;
            margin-left: 0px;
            margin-top: 1.2rem;
            margin-left: 0.3rem;
            margin-right: 0.1rem;
            margin-bottom: -0.8rem;
            padding: 0px;
        } 
    }
    .reportEntity {
        color: #125362;
        font-size: 1.5em !important;
        margin-left: 0.2rem !important;
    }
    .entitytext {
        color:#125362 !important;
    }
    .reportFilters {
        color:#CF9B5C;
        font-size: 1.5em !important;
        margin-left: 0.2rem !important;
        
    }
    .filtertext {
        color:#CF9B5C !important;
    }
    .reportGrpHdrs {
        color: #8160B0;
        font-size: 1.5em !important;
        margin-left: 0.2rem !important;
        
    }
    .hdrtext {
        color: #8160B0 !important;
    }
    .reportGrpSumamry {
        color: #697DCD;
        font-size: 1.5em !important;
        margin-left: 0.2rem !important;
        
    }
    .grpsummarytext {
        color: #697DCD !important;
    }
    .reportSumamry {
        color: #B47E7E;
        font-size: 1.5em !important;
        margin-left: 0.2rem !important;
    }
    .summarytext {
        color: #B47E7E !important;
    }
    // .top_border {
    //     border-top-style: dashed !important;
    //     border-top-width: thin !important;
    // }
    // .left_border {
    //     border-left-style: dashed !important;
    //     border-left-width: thin !important;
    //     left: 20%;
    // }
    .left_border {
        border-left: 0.5px dashed #000000;
        height: 73vh;
        position: absolute;
        left: 2%;
        margin-left: -3px;
        top: 0;
      }
      .top_border {
      border-top: 0.5px dashed #000000;
        margin-top: 31px;
      }
      .right_border {
        border-right: 0.5px dashed #000000;
        height: 73vh;
        position: absolute;
        left: 113rem !important;
        margin-left: -3px;
        top: 0;
      }
}

.prevoius-fills-refills{
    td,th {
        border: 1px solid transparent;
    }
    .table>thead>tr>th
    {
        border-bottom: 2px solid #dfdada;
        border-top: 2px solid  transparent;
    }
    .table>thead>tr>td{
        font-weight: 300 !important;
    }
}
.alignItems_center {
    display: flex;
    align-items: center;
}
.divBorderStyle {
    color:black;
}

.patchElemCSS{
    list-style-type: none !important;
    font-size: unset !important;
    background: #E0EDF0;
    // cursor: not-allowed;
}
.patchColmFuncCSS{
    list-style-type: none !important;
    font-size: unset !important;
    background: #E0EDF0;
    cursor: not-allowed;
}
.patchFiltcCSS{
    list-style-type: none !important;
    font-size: unset !important;
    background: #FEF4E8;
    cursor: not-allowed;
}
.noDropCursor{
    cursor: no-drop !important;
}
.patchGrpHdrcCSS{
    list-style-type: none !important;
    font-size: unset !important;
    background: #F5EEFF;
    cursor: not-allowed;
}
.patchGrpSumcCSS{
    list-style-type: none !important;
    font-size: unset !important;
    background: #ECEFFB;
    cursor: not-allowed;
}
.patchRptSumCSS{
    list-style-type: none !important;
    font-size: unset !important;
    background: #FFEEEE;
    cursor: not-allowed;
}
.sort--icon_CSS {
    border-radius: 10%;
    width: 33px;
    height: 33px !important;
    text-align: center;
    display: inline-block;
    margin-right: 2px;
    font-size: 22px;
}
.modal--body_height {
    overflow-y: scroll;
    overflow-x: hidden;
}
.max--height_55 {
    max-height: 55vh !important;
}
.max--height_71 {
    max-height: 71vh !important;
}
.font_Styles--400_1--1rm {
    font-weight: 400 !important;
    font-size: 1.1rem !important
}
.font_Styles--400--1rm {
    font-weight: 400 !important;
    font-size: 1rem !important
}
.font_size--1rm {
    font-size: 1rem !important
}
.icons--position_right {
    position: absolute !important;
    right: 20px !important;
}
.center_alignment{
    display: inline-flex !important;
    align-items: center !important;
}
.actions_center-align {
    margin: auto !important;
    display: inline-block !important;
    vertical-align: middle !important;
    line-height: normal !important;
}
.inline-Dropdown--height {
    position: absolute;
    height: 2.2rem !important;
}
.section_height--max{
    max-height: 7vh !important;
    overflow-y: scroll !important;
}
.overflow-wrap{
    overflow-wrap: break-word;
}
.screen-height--64vh {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 64vh !important;
}
.document--border{
    border: 3px #f7941d solid !important;
    border-left: 20px #f7941d solid !important;
}
.header-Colors{
    background: #31afe5;
    color: #FFFFFF;
    font-weight: 700;
}
.dashed-horizontal--line {
    border-top: dotted 2px;
    color: #31afe5;
    margin-top: 0.7rem;
}

.labelPrthist {
    background-color: #FFE8B6 !important;
    font-weight: 400;
    color: #000000;
}
.Hoa_css{
    width: 70px !important;
    height: 38px !important;
}

.hoa_button{
    background: #31afe5;
    border: 0.1rem solid #31afe5;
    border-radius: 0.2rem;
    color: #FFFFFF;
    min-width: 4.5rem !important;
    position: relative;
    top: 16px;
    height: 2.4rem;
}
.inactive_button{
    color: #888888;
    background: #F5F5F5;
    border: 0.07rem solid #a6a8b3 !important;
    border-radius: 0.2rem;
    min-width: 4.5rem !important;
    position: relative;
    top: 16px;
    height: 2.4rem;
    cursor: not-allowed !important;
}
.add-record--GrpHeader {
    color: #51A351;
    font-size: 20px;
}
.vertical--align-middle {
    vertical-align: middle !important;
}
.checkbox-align--center {
    &.checkAll--Box {
        transform: translate(25%, 11%) !important;
    }
    &.check--Indv {
        transform: translate(-4%, 11%) !important;
    }
    
}
.check-button {
    border: 1px solid green;
    padding: 0px 11px 0px 25px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
}
.clinical-alert {
    border: 1px solid red;
    background: #faebd8;
    color: red;
    font-weight: bold;
}
.grey {
    background: grey;
    font-weight: bold;
    color: white;
}
.grey_border {
    border: 1px solid grey;
}
.check {
   padding : 2px 7px;
}
.check-alert {
    padding: 2px 10px;
}
.clinicalCheck_icons {
    position: absolute;
    border-radius: 50%;
    margin-top: -2px;
}
.gsddHasEqus_text{
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 16px;
    color: red;
    font-weight: 500;
    margin-bottom: 1rem;
}

.reset_disable_color{
    color: #a2a7ad;
}

.pa-wijgrid {
    position: absolute;
    width: 93%;
    z-index: 999;
    left: 13px;
    margin-top: 0.4rem !important;
}

.Pa-timer-icon {
    color: #999999; 
    font-size: 15px; 
    position: absolute; 
    top: 25px;
    right: 8px;
}
.patientpresc_search_report{
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    min-height: 35px; 
    overflow: hidden; 
    max-height: 63px;
    overflow-y: scroll;
    margin-top: 12px;
}
.loaderMsg--align {
    text-align: left;
    width:119%
}
.pa-dropdown {
    position: absolute;
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    width: 59%;
    border-radius: 0.25rem;
    z-index: 9999;
    &.pa-list {
        width: none;
        right: 171px;
        bottom: 42px;
        background: none;
        border: none;
    }
}
.border-none {
    border: none;
}
.pasort-icon {
    position: absolute;
    color: #999999;
    font-size: 18px;
    top: 10px;
    right: 46%;
    &.up_icon {
        top: 13px; 
    }
}
.icons-centerTo--gridCell {
    position: relative;
    top: 0.1rem;
}